import { Project } from '@core/domain/models/project.model'
import { Review } from '@core/domain/models/review.model'
import { Id } from '@core/domain/types/id.type'
import { OxfordLevelOfEvidenceType } from '@core/domain/types/oxford-level-of-evidence-type'
import { Api } from './review-manager-api'
import { MetaData } from '@core/domain/types/meta-data.type'
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { PdfStudyRetrievalStatus } from '@core/domain/types/PdfStudyRetrievalStatus'
import { InclusionCriterion } from '@core/domain/models/InclusionCriterion.model'
import { PeerReviewStatus } from '@core/domain/types/peerReview.type'
import { StudyDesignStatus } from '@core/domain/types/studyDesignStatus.type'
import { StudyDesign } from '@core/domain/models/studyDesign.model'
import { parseRISText } from '@core/utils/parse-studies-file'
import { ReviewItemType } from '@core/domain/types/reviewItemType.type'

export class ReviewsRepository {
  constructor(private readonly _reviewManagerService: Api<unknown>) {}
  async findById(reviewId: Id): Promise<Review> {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerFindById(reviewId, {
        shouldIncludeSearches: true,
      })
    return Review.create(data as any)
  }

  async delete(selectedReviewsIds: Id[]) {
    await this._reviewManagerService.instance.delete(
      `/api/reviews/${selectedReviewsIds}`,
    )
  }

  async markStudyAsDuplicate(
    reviewId: Id,
    studyId: Id,
    parentStudyId: Id,
  ): Promise<void> {
    await this._reviewManagerService.instance.patch(
      `/api/reviews/${reviewId}/studies/${studyId}/mark-as-duplicate`,
      { parentStudyId },
    )
  }

  async markStudyAsNotDuplicate(reviewId: Id, studyId: Id): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerMarkStudyAsNotDuplicate(
      reviewId,
      studyId,
    )
  }

  async setStudyTitleAndAbstractScreening(
    reviewId: Id,
    studyId: Id,
    titleAndAbstractScreening: string,
  ): Promise<void> {
    await this._reviewManagerService.instance.patch(
      `/api/reviews/${reviewId}/studies/${studyId}/title-and-abstract-screening`,
      { titleAndAbstractScreening },
    )
  }

  async clearStudyTitleAndAbstractScreening(reviewId: Id, studyId: Id) {
    await this._reviewManagerService.api.reviewsControllerClearStudyTitleAndAbstractScreening(
      reviewId,
      studyId,
    )
  }

  async setStudyFullTextScreening(
    reviewId: Id,
    studyId: Id,
    fullTextScreening: string,
  ): Promise<void> {
    await this._reviewManagerService.instance.patch(
      `/api/reviews/${reviewId}/studies/${studyId}/full-text-screening`,
      { fullTextScreening },
    )
  }

  async clearStudyFullTextScreening(reviewId: Id, studyId: Id) {
    await this._reviewManagerService.api.reviewsControllerClearStudyFullTextScreening(
      reviewId,
      studyId,
    )
  }

  async deleteSearchStudies(reviewId: Id, searchId: number) {
    await this._reviewManagerService.api.reviewsControllerDeleteSearch(
      reviewId,
      searchId,
    )
  }

  async planScreening(
    reviewId: number,
    data: {
      titleAndAbstractCriteria: string[]
      fullTextCriteria: string[]
    },
  ): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerPlanScreening(
      reviewId,
      data,
    )
  }

  async addFullTextCriterion(
    reviewId: number,
    criterion: string,
  ): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerAddFullTextCriterion(
      reviewId,
      { criterion: criterion },
    )
  }

  async deleteFullTextCriterion(
    reviewId: number,
    criterion: string,
  ): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerDeleteFullTextCriterion(
      reviewId,
      { criterion: criterion },
    )
  }

  async addPicoInclusionCriterion(
    reviewId: number,
    newCriterion: { criterionType: InclusionCriterion; criterion: string },
  ): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerAddPicoInclusionluCriterion(
      reviewId,
      newCriterion,
    )
  }

  async addInclusionCriterion(
    reviewId: number,
    criterion: string,
  ): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerAddInclusionluCriterion(
      reviewId,
      { criterion },
    )
  }

  async deletePicoInclusionCriterion(
    reviewId: number,
    newCriterion: { criterionType: InclusionCriterion; criterion: string },
  ): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerDeletePicoInclusionCriterion(
      reviewId,
      newCriterion,
    )
  }

  async deleteInclusionCriterion(
    reviewId: number,
    criterion: string,
  ): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerDeleteInclusionCriterion(
      reviewId,
      { criterion },
    )
  }

  async enableTitleAndAbstractScreening(reviewId: number): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerEnableTitleAndAbstractScreening(
      reviewId,
    )
  }

  async disableTitleAndAbstractScreening(reviewId: number): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerDisableTitleAndAbstractScreening(
      reviewId,
    )
  }

  async deleteTitleAndAbstractCriterion(
    reviewId: number,
    criterion: string,
  ): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerDeleteTitleAndAbstractCriterion(
      reviewId,
      { criterion: criterion },
    )
  }

  async addTitleAndAbstractCriterion(
    reviewId: number,
    criterion: string,
  ): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerAddTitleAndAbstractCriterion(
      reviewId,
      { criterion: criterion },
    )
  }

  async findProject(reviewId: Id): Promise<Project> {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerFindProject(
        reviewId,
      )
    return new Project(data as Partial<Project>)
  }

  async favoriteStudy(reviewId: Id, studyId: Id) {
    await this._reviewManagerService.api.reviewsControllerFavoriteStudy(
      reviewId,
      studyId,
    )
  }

  async unfavoriteStudy(reviewId: Id, studyId: Id) {
    await this._reviewManagerService.api.reviewsControllerUnfavoriteStudy(
      reviewId,
      studyId,
    )
  }

  async updateName(reviewId: Id, name: string): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerUpdateName(reviewId, {
      name,
    })
  }

  async uploadStudyPdfFile(reviewId: Id, studyId: Id, file: File) {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerUploadStudyPdfFile(
        reviewId,
        studyId,
        { file },
      )
    return data
  }

  async getStudyPdfFile(reviewId: Id, studyId: Id): Promise<Blob> {
    const { data } = await this._reviewManagerService.instance.get(
      `/api/reviews/${reviewId}/studies/${studyId}/pdf`,
      {
        responseType: 'blob',
      },
    )

    return data as unknown as Blob
  }

  async downloadSearchCitationFile(reviewId: Id, searchId: Id): Promise<Blob> {
    const { data } = await this._reviewManagerService.instance.get(
      `/api/reviews/${reviewId}/searches/${searchId}/citation-file`,
      {
        responseType: 'blob',
      },
    )

    return data as unknown as Blob
  }

  async deleteStudyPdfFile(reviewId: Id, studyId: Id): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerDeleteStudyPdfFile(
      reviewId,
      studyId,
    )
  }

  async downloadPdfZip(reviewId: Id): Promise<Blob> {
    const { data } = await this._reviewManagerService.instance.get(
      `/api/reviews/${reviewId}/studies/zip`,
      {
        responseType: 'blob',
      },
    )

    return data as unknown as Blob
  }

  async editStudyAbstract(
    reviewId: Id,
    studyId: Id,
    abstract: string,
  ): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerEditStudyAbstract(
      reviewId,
      studyId,
      { abstract },
    )
  }

  async removeImportSourceFromPlan(reviewId: Id, importSourceId: string) {
    await this._reviewManagerService.api.reviewsControllerRemoveImportSourceFromPlan(
      reviewId,
      {
        importSourceId,
      },
    )
  }

  async enableStudiesAppraisalImdrfMdce2019(reviewId: Id) {
    await this._reviewManagerService.api.reviewsControllerEnableStudiesAppraisalImdrfMdce2019(
      reviewId,
    )
  }

  async enableStudiesAppraisalOxfordLevelOfEvidence(reviewId: Id) {
    await this._reviewManagerService.api.reviewsControllerEnableStudiesAppraisalOxfordLevelOfEvidence(
      reviewId,
    )
  }

  async enablePicoInclusionCriteria(reviewId: Id) {
    await this._reviewManagerService.api.reviewsControllerEnablePicoInclusionCriteria(
      reviewId,
    )
  }

  async enableStudiesAppraisalPeerReviewStatus(reviewId: Id) {
    await this._reviewManagerService.api.reviewsControllerEnableStudiesAppraisalPeerReviewStatus(
      reviewId,
    )
  }

  async disableStudiesAppraisal(reviewId: Id) {
    await this._reviewManagerService.api.reviewsControllerDisableStudiesAppraisal(
      reviewId,
    )
  }

  async disablePicoInclusionCriteria(reviewId: Id) {
    await this._reviewManagerService.api.reviewsControllerDisablePicoInclusionCriteria(
      reviewId,
    )
  }

  async disableStudiesAppraisalOxfordLevelOfEvidence(reviewId: Id) {
    await this._reviewManagerService.api.reviewsControllerDisableStudiesAppraisalOxfordLevelOfEvidence(
      reviewId,
    )
  }

  async disableStudiesAppraisalPeerReviewStatus(reviewId: Id) {
    await this._reviewManagerService.api.reviewsControllerDisableStudiesAppraisalPeerReviewStatus(
      reviewId,
    )
  }

  async submitComplaint(
    reviewId: Id,
    studyId: Id,
    complaintData: {
      body: string
      fromAddress: string
      fromName: string
      subject: string
      to: string
    },
  ): Promise<Date> {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerSubmitComplaint(
        reviewId,
        studyId,
        complaintData,
      )
    return new Date(data as unknown as string)
  }

  async appraiseStudy(
    reviewId: Id,
    studyId: Id,
    appraisal: { criterionId: string; gradeId: string },
  ) {
    return this._reviewManagerService.api.reviewsControllerAppraiseStudy(
      reviewId,
      studyId,
      { criterionId: appraisal.criterionId, gradeId: appraisal.gradeId },
    )
  }

  async setOxfordLevelOfEvidenceStudy(
    reviewId: Id,
    studyId: Id,
    oxfordLevelOfEvidence: OxfordLevelOfEvidenceType,
  ) {
    return this._reviewManagerService.api.reviewsControllerSetOxfordLevelOfEvidenceStudy(
      reviewId,
      studyId,
      { oxfordLevelOfEvidence: oxfordLevelOfEvidence },
    )
  }

  async setStudyPeerReviewStatus(
    reviewId: Id,
    studyId: Id,
    peerReviewStatus: PeerReviewStatus,
  ) {
    return this._reviewManagerService.api.reviewsControllerSetPeerReviewStatus(
      reviewId,
      studyId,
      { peerReviewStatus },
    )
  }

  async setStudyDesignStatus(
    reviewId: Id,
    studyId: Id,
    studyDesignStatus: StudyDesignStatus,
  ) {
    return this._reviewManagerService.api.reviewsControllerSetStudyDesignStatus(
      reviewId,
      studyId,
      { studyDesignStatus },
    )
  }

  async cleaStudyOxfordLevelOfEvidence(reviewId: Id, studyId: Id) {
    return this._reviewManagerService.api.reviewsControllerClearStudyOxfordLevelOfEvidence(
      reviewId,
      studyId,
    )
  }

  async clearStudyPeerReviewStatus(reviewId: Id, studyId: Id) {
    return this._reviewManagerService.api.reviewsControllerClearStudyPeerReviewStatus(
      reviewId,
      studyId,
    )
  }

  async clearStudiesAppraisal(reviewId: Id, studyId: Id, criterionId: string) {
    return this._reviewManagerService.api.reviewsControllerClearStudyAppraisal(
      reviewId,
      studyId,
      criterionId,
    )
  }

  async updateStudy(
    reviewId: Id,
    studyId: Id,
    metaData: MetaData,
  ): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerUpdateStudy(
      reviewId,
      studyId,
      metaData,
    )
  }

  async addAttributeStructure(
    reviewId: Id,

    attribute: { label: string; question: string; order: number },
  ): Promise<AttributeStructure> {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerAddAttributeStructure(
        reviewId,
        attribute,
      )

    return data
  }

  async addMultipleAttributesStructure(
    reviewId: Id,
    attributes: { label: string; question: string; order: number }[],
  ): Promise<AttributeStructure[]> {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerAddMultipleAttributeStructure(
        reviewId,
        { attributes: attributes },
      )

    return data
  }

  async updateStudySynthesisAttribute(
    reviewId: Id,
    studyId: Id,
    attribute: { id: string; value: string },
  ) {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerUpdateStudySynthesisAttribute(
        reviewId,
        studyId,
        attribute,
      )

    return data
  }

  async lockPlan(reviewId: Id) {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerPlanLocking(
        reviewId,
      )

    return data
  }

  async unlockPlan(reviewId: Id) {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerPlanUnlocking(
        reviewId,
      )

    return data
  }

  async deleteAttributeStructure(reviewId: Id, attributesStructureId: string) {
    await this._reviewManagerService.api.reviewsControllerDeleteAttributeStructure(
      reviewId,
      attributesStructureId,
    )
  }

  async editAttributeStructure(
    reviewId: Id,
    updatedAttribute: AttributeStructure,
  ) {
    await this._reviewManagerService.api.reviewsControllerEditAttributeStructure(
      reviewId,
      {
        id: updatedAttribute.id,
        label: updatedAttribute.label,
        question: updatedAttribute.question,
        order: updatedAttribute.order,
      },
    )
  }

  async addAuthor(reviewId: Id, authorId: string) {
    await this._reviewManagerService.api.reviewsControllerAddAuthor(
      reviewId,
      authorId,
    )
  }

  async addReviewer(reviewId: Id, reviewerId: string) {
    await this._reviewManagerService.api.reviewsControllerAddReviewer(
      reviewId,
      reviewerId,
    )
  }

  async addApprover(reviewId: Id, approverId: string) {
    await this._reviewManagerService.api.reviewsControllerAddApprover(
      reviewId,
      approverId,
    )
  }

  async addExternalApprover(reviewId: Id, externalApproverId: string) {
    await this._reviewManagerService.api.reviewsControllerAddExternalApprover(
      reviewId,
      externalApproverId,
    )
  }

  async removeAuthor(reviewId: Id, authorId: string) {
    await this._reviewManagerService.api.reviewsControllerUnassignAuthor(
      reviewId,
      authorId,
    )
  }

  async removeReviewer(reviewId: Id, reviewerId: string) {
    await this._reviewManagerService.api.reviewsControllerUnassignReviewer(
      reviewId,
      reviewerId,
    )
  }

  async removeApprover(reviewId: Id, approverId: string) {
    await this._reviewManagerService.api.reviewsControllerUnassignApprover(
      reviewId,
      approverId,
    )
  }

  async removeExternalApprover(reviewId: Id, externalApproverId: string) {
    await this._reviewManagerService.api.reviewsControllerUnassignExternalApprover(
      reviewId,
      externalApproverId,
    )
  }

  async addCustomImportSourceToPlan(
    reviewId: Id,
    importSource: {
      id?: string
      name: string
      url: string
      type: ImportSourceType
      description?: string
    },
  ): Promise<string> {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerAddCustomImportSource(
        reviewId,
        importSource,
      )
    return data
  }
  async importRisSearch({
    citationFiles,
    date,
    query,
    filters,
    reviewId,
    importSourceId,
  }: {
    query?: string
    filters?: string
    citationFiles: File[]
    date: string
    reviewId: number
    importSourceId: string
  }): Promise<void> {
    const articles = (
      await Promise.all(
        citationFiles.map(async (file) => {
          const text = await file.text()
          const metadata = parseRISText(text)
          return metadata.map((m) => ({
            ...m,
            type: ReviewItemType.Article as
              | 'article'
              | 'guidance'
              | 'incident'
              | 'study'
              | 'fieldSafetyNotice'
              | 'standard',
          }))
        }),
      )
    ).flat()
    await this._reviewManagerService.api.reviewsControllerImportSearch(
      reviewId,
      {
        query,
        filters,
        importSourceId,
        date,
        items: articles,
      },
      { headers: { 'Content-Type': 'multipart/form-data' } },
    )
  }

  async importCitationSearch({
    citationFiles,
    reviewId,
    parentStudyId,
  }: {
    citationFiles: File[]
    reviewId: number
    parentStudyId: number
  }): Promise<void> {
    const formData = new FormData()
    formData.append('parentStudyId', parentStudyId.toString())
    citationFiles?.forEach((cf) => {
      formData.append('citationFiles', cf)
    })

    await this._reviewManagerService.instance.post(
      `/api/reviews/${reviewId}/import-citation-search`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    )
  }

  async answerStudyQuestion(
    studyId: number,
    question: string,
  ): Promise<string> {
    const { data } =
      await this._reviewManagerService.api.studiesControllerAnswerStudyQuestion(
        studyId,
        {
          question,
        },
      )
    return data
  }

  async findRunningStudiesPdfImports(
    reviewId: number,
  ): Promise<
    { jobId: string; status: PdfStudyRetrievalStatus; studyId: number }[]
  > {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerFindRunningStudiesPdfImports(
        reviewId,
      )
    return (
      data as unknown as {
        jobId: string
        status: PdfStudyRetrievalStatus
        studyId: number
      }[]
    ).map(
      ({
        jobId,
        status,
        studyId,
      }: {
        jobId: string
        status: PdfStudyRetrievalStatus
        studyId: number
      }) => ({
        jobId,
        status,
        studyId,
      }),
    )
  }

  async addAppraisalCriteria(
    reviewId: number,
    data: { appraisalCriterionId: string; answer: string },
  ): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerAddAppraisalCriteria(
      reviewId,
      data,
    )
  }

  async deleteAppraisalCriterionAnswer(
    reviewId: number,
    data: { answerId: string; appraisalCriterionId: string },
  ): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerDeleteAppraisalCriterionAnswer(
      reviewId,
      data,
    )
  }

  async lockReview(reviewId: Id): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerLockReview(reviewId)
  }

  async unlockReview(reviewId: Id): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerUnlockReview(reviewId)
  }

  async archiveReview(reviewId: Id): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerArchiveReview(
      reviewId,
    )
  }

  async unarchiveReview(reviewId: Id): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerUnarchiveReview(
      reviewId,
    )
  }

  async updateStudyDesign(reviewId: Id, studyId: Id, studyDesign: StudyDesign) {
    return this._reviewManagerService.api.reviewsControllerUpdateStudyDesign(
      reviewId,
      studyId,
      studyDesign,
    )
  }
}
