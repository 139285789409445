<template>
  <div class="flex-grow h-full">
    <div class="px-4 text-lg">
      The goal is to identify the following information concerning
      <input
        v-model="alternative"
        type="text"
        class="flex-1 pl-1 focus:outline-none border-b-2 min-w-[350px] py-2 transition-colors"
        :class="[isFocused ? 'border-black' : 'border-black/30']"
        placeholder="type the alternative here and press enter"
        @keydown.enter="applyPreset"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import useLoading from '@app/composables/use-loading'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { ref } from 'vue'
const loading = useLoading()

const review = injectStrict(ReviewKey)

const isFocused = ref()

function generatePreset(alternative: string) {
  return [
    {
      label: 'Definition',
      question: 'What is ' + alternative + '?',
      order: 1,
    },
    {
      label: 'Alternative names',
      question: 'Are there alternative names for ' + alternative + '?',
      order: 2,
    },
    {
      label: 'Age considerations',
      question: 'How does age impact ' + alternative + '?',
      order: 3,
    },

    {
      label: 'Gender considerations',
      question: 'How does gender influence ' + alternative + '?',
      order: 4,
    },

    {
      label: 'Race considerations',
      question: 'What is the effect of race on ' + alternative + '?',
      order: 5,
    },

    {
      label: 'Ethnicity considerations',
      question: 'How does ethnicity impact ' + alternative + '?',
      order: 6,
    },

    {
      label: 'Environmental considerations',
      question: 'Do environmental factors affect ' + alternative + '?',
      order: 7,
    },
    {
      label: 'Healthcare-system specific considerations',
      question:
        'What are the known effects of ' +
        alternative +
        ' For each reported effect specify mean or mean difference and confidence interval, if available.',
      order: 8,
    },
    {
      label: 'Known risk and side effects',
      question:
        'What are the known risks and side-effects of ' +
        alternative +
        ' For each risk and side-effect specify a severity and probability with confidence interval, if available.',
      order: 9,
    },
  ]
}
const alternative = ref()

async function applyPreset() {
  const presets = generatePreset(alternative.value)

  try {
    loading.start()
    await review.addMultipleAttributesStructure(
      review.entity.value.id,
      presets,
    )
  } catch (error) {
    console.error('Error adding scope field:', error)
  } finally {
    loading.stop()
  }
}
</script>
