<template>
  <div
    class="flex flex-col border border-[#D9E4EA] p-3 space-y-1 max-w-[600px]"
  >
    <div class="flex justify-between">
      <div class="flex items-center gap-2">
        <p class="text-sm text-primary font-medium">Is applicable ?</p>
        <div class="flex divide-y gap-2 divide-gray-200">
          <button
            class="gap-1 px-3 py-2 text-sm rounded flex items-center justify-center"
            :class="
              studyDesignStatus === StudyDesignStatus.applicable
                ? 'bg-green-200 text-green-800 fill-green-800'
                : 'bg-slate-200 text-slate-800 fill-slate-800'
            "
            @click.stop="
              setStudyDesignStatus(props.study.id, StudyDesignStatus.applicable)
            "
          >
            Yes
          </button>
          <button
            class="gap-1 px-3 py-2 text-sm rounded flex items-center justify-center"
            :class="
              studyDesignStatus === StudyDesignStatus.notApplicable
                ? 'bg-red-200 text-red-800 fill-red-800'
                : 'bg-slate-200 text-slate-800 fill-slate-800'
            "
            @click.stop="
              setStudyDesignStatus(
                props.study.id,
                StudyDesignStatus.notApplicable,
              )
            "
          >
            No
          </button>
        </div>
      </div>
      <button
        v-if="studyDesignStatus === StudyDesignStatus.applicable"
        @click="expansionPanel.toggle()"
      >
        <ChevronDownIcon v-if="!expansionPanel.isShown.value" class="h-5" />
        <ChevronUpIcon v-else class="h-5" />
      </button>
    </div>
    <div
      v-if="
        studyDesignStatus === StudyDesignStatus.applicable &&
        expansionPanel.isShown.value
      "
    >
      <div>
        <GenericAutoComplete
          label="Countries"
          :items="countries"
          :item-options="{
            displayProperty: 'name',
            filterProperties: ['code', 'name'],
            valueProperty: 'code',
          }"
          :placeholder="'select country'"
          class="w-full"
          :shadow="false"
          :model-value="''"
          @update:model-value="(value: any) => addCountry(value)"
        >
        </GenericAutoComplete>

        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="country in studyDesign.countries"
            :key="'contry-code-' + country"
            class="flex items-center text-xs border-2 gap-1 px-3 py-1 rounded-full text-black leading-none bg-slate-200"
          >
            {{ country }}
            <button
              class="hover:bg-transparent"
              @click.stop="removeCountry(country)"
            >
              <XCircleIcon class="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>

      <div>
        <p class="text-sm text-primary font-medium">Number of sites</p>

        <InlineTextInput
          v-model="sitesNumber"
          class="h-full w-full"
          editor-classes="h-full"
          @save="updateStudyDesignProperty('sites', Number($event))"
        />
      </div>

      <div>
        <p class="text-sm text-primary font-medium">
          Planned number of subjects
        </p>

        <InlineTextInput
          v-model="plannedNumberOfSubjects"
          class="h-full w-full"
          editor-classes="h-full"
          @save="
            updateStudyDesignProperty('plannedNumberOfSubjects', Number($event))
          "
        />
      </div>

      <div>
        <GenericAutoComplete
          v-model="originalStudyDesign.direction"
          label="Direction"
          placeholder="Direction"
          :item-options="{
            displayProperty: 'text',
            valueProperty: 'value',
            filterProperties: ['text', 'value'],
          }"
          :items="directions"
          @update:model-value="
            (value: any) =>
              updateStudyDesignDirection(value as StudyDesignDirection)
          "
        />
      </div>

      <div v-if="studyDesign.direction !== StudyDesignDirection.Retrospective">
        <GenericAutoComplete
          v-model="originalStudyDesign.type"
          label="Type"
          placeholder="Type"
          :item-options="{
            displayProperty: 'text',
            valueProperty: 'value',
            filterProperties: ['text', 'value'],
          }"
          :items="types"
          @update:model-value="
            (value: any) => updateStudyDesignProperty('type', value)
          "
        />
      </div>

      <div>
        <p class="text-sm text-primary font-medium">Design</p>

        <InlineTextInput
          v-model="studyDesign.design"
          class="h-full w-full"
          editor-classes="h-full"
          :multiline="true"
          @save="updateStudyDesignProperty('design', $event)"
        />
      </div>

      <div>
        <div class="flex-col">
          <p class="text-sm text-primary font-medium">Inclusion criteria</p>
          <div class="flex flex-wrap gap-2 items-center">
            <div
              v-for="criterion in studyDesign.inclusionCriteria"
              :key="'studyDesign-inclusion-criterion-' + criterion"
              class="flex items-center text-sm gap-1 px-3 py-1 min-h-6 rounded-full text-green-800 bg-green-200 leading-none"
            >
              {{ criterion }}
              <button @click="removeCriterion('inclusionCriteria', criterion)">
                <XCircleIcon class="w-5 h-5" />
              </button>
            </div>
            <FloatingTextInput
              @save="addCriterion('inclusionCriteria', $event)"
            >
              <template #activator="{ show }">
                <button
                  key=""
                  class="text-sm border-2 p-1 px-3 rounded-full text-primary border-blue-200 border-dashed"
                  @click.stop="show"
                >
                  + Add criterion
                </button>
              </template>
            </FloatingTextInput>
          </div>
        </div>
      </div>

      <div>
        <div class="flex-col">
          <p class="text-sm text-primary font-medium">Exclusion criteria</p>
          <div class="flex flex-wrap gap-2 items-center">
            <div
              v-for="criterion in studyDesign.exclusionCriteria"
              :key="'studyDesign-exclusion-criterion-' + criterion"
              class="flex items-center text-sm gap-1 px-3 py-1 min-h-6 rounded-full text-red-800 bg-red-200 leading-none"
            >
              {{ criterion }}
              <button @click="removeCriterion('exclusionCriteria', criterion)">
                <XCircleIcon class="w-5 h-5" />
              </button>
            </div>
            <FloatingTextInput
              @save="addCriterion('exclusionCriteria', $event)"
            >
              <template #activator="{ show }">
                <button
                  key=""
                  class="text-sm border-2 p-1 px-3 rounded-full text-primary border-blue-200 border-dashed"
                  @click.stop="show"
                >
                  + Add criterion
                </button>
              </template>
            </FloatingTextInput>
          </div>
        </div>
      </div>

      <div>
        <div class="flex-col">
          <p class="text-sm text-primary font-medium">Primary outcome</p>
          <div class="flex flex-wrap gap-2 items-center">
            <div
              v-for="criterion in studyDesign.primaryOutcome"
              :key="'studyDesign-primary-outcome-' + criterion"
              class="flex items-center text-sm gap-1 px-3 py-1 min-h-6 rounded-full text-green-800 bg-green-200 leading-none"
            >
              {{ criterion }}
              <button @click="removeCriterion('primaryOutcome', criterion)">
                <XCircleIcon class="w-5 h-5" />
              </button>
            </div>
            <FloatingTextInput @save="addCriterion('primaryOutcome', $event)">
              <template #activator="{ show }">
                <button
                  key=""
                  class="text-sm border-2 p-1 px-3 rounded-full text-primary border-blue-200 border-dashed"
                  @click.stop="show"
                >
                  + Add outcome
                </button>
              </template>
            </FloatingTextInput>
          </div>
        </div>
      </div>

      <div>
        <div class="flex-col">
          <p class="text-sm text-primary font-medium">Secondary outcome</p>
          <div class="flex flex-wrap gap-2 items-center">
            <div
              v-for="criterion in studyDesign.secondaryOutcome"
              :key="'studyDesign-secondary-outcome-' + criterion"
              class="flex items-center text-sm gap-1 px-3 py-1 min-h-6 rounded-full text-green-800 bg-green-200 leading-none"
            >
              {{ criterion }}
              <button @click="removeCriterion('secondaryOutcome', criterion)">
                <XCircleIcon class="w-5 h-5" />
              </button>
            </div>
            <FloatingTextInput @save="addCriterion('secondaryOutcome', $event)">
              <template #activator="{ show }">
                <button
                  key=""
                  class="text-sm border-2 p-1 px-3 rounded-full text-primary border-blue-200 border-dashed"
                  @click.stop="show"
                >
                  + Add outcome
                </button>
              </template>
            </FloatingTextInput>
          </div>
        </div>
      </div>

      <div>
        <p class="text-sm text-primary font-medium">Is the study controlled?</p>
        <div class="flex divide-y gap-2 divide-gray-200">
          <button
            v-for="status in StudyDesignOptionsStatus"
            :key="status"
            class="gap-1 px-3 py-2 text-sm rounded flex items-center justify-center text-black"
            :class="[getStudyDesignOptionsButtonState('controlled', status)]"
            @click="
              studyDesign.controlled === status
                ? updateStudyDesignProperty(
                    'controlled',
                    StudyDesignOptions.default,
                  )
                : updateStudyDesignProperty('controlled', status)
            "
          >
            {{ studyDesignOptionsStatusMap[status as 'yes' | 'no'].text }}
          </button>
        </div>
      </div>
      <div v-if="studyDesign.direction !== StudyDesignDirection.Retrospective">
        <p class="text-sm text-primary font-medium">
          Were participants blinded?
        </p>
        <div class="flex divide-y gap-2 divide-gray-200">
          <button
            v-for="status in StudyDesignOptionsStatus"
            :key="status"
            class="gap-1 px-3 py-2 text-sm rounded flex items-center justify-center text-black"
            :class="[
              getStudyDesignOptionsButtonState('participantBlinding', status),
            ]"
            @click="
              studyDesign.participantBlinding === status
                ? updateStudyDesignProperty(
                    'participantBlinding',
                    StudyDesignOptions.default,
                  )
                : updateStudyDesignProperty('participantBlinding', status)
            "
          >
            {{ studyDesignOptionsStatusMap[status as 'yes' | 'no'].text }}
          </button>
        </div>
      </div>
      <div v-if="studyDesign.direction !== StudyDesignDirection.Retrospective">
        <p class="text-sm text-primary font-medium">
          Were researchers blinded?
        </p>
        <div class="flex divide-y gap-2 divide-gray-200">
          <button
            v-for="status in StudyDesignOptionsStatus"
            :key="status"
            class="gap-1 px-3 py-2 text-sm rounded flex items-center justify-center text-black"
            :class="[
              getStudyDesignOptionsButtonState('researcherBlinding', status),
            ]"
            @click="
              studyDesign.researcherBlinding === status
                ? updateStudyDesignProperty(
                    'researcherBlinding',
                    StudyDesignOptions.default,
                  )
                : updateStudyDesignProperty('researcherBlinding', status)
            "
          >
            {{ studyDesignOptionsStatusMap[status as 'yes' | 'no'].text }}
          </button>
        </div>
      </div>
      <div>
        <p class="text-sm text-primary font-medium">
          Was the analysis blinded?
        </p>
        <div class="flex divide-y gap-2 divide-gray-200">
          <button
            v-for="status in StudyDesignOptionsStatus"
            :key="status"
            class="gap-1 px-3 py-2 text-sm rounded flex items-center justify-center text-black"
            :class="[
              getStudyDesignOptionsButtonState('analysisBlinding', status),
            ]"
            @click="
              studyDesign.analysisBlinding === status
                ? updateStudyDesignProperty(
                    'controlled',
                    StudyDesignOptions.default,
                  )
                : updateStudyDesignProperty('analysisBlinding', status)
            "
          >
            {{ studyDesignOptionsStatusMap[status as 'yes' | 'no'].text }}
          </button>
        </div>
      </div>
      <div v-if="studyDesign.direction !== StudyDesignDirection.Retrospective">
        <p class="text-sm text-primary font-medium">Is follow-up ?</p>
        <div class="flex divide-y gap-2 divide-gray-200">
          <button
            v-for="status in StudyDesignOptionsStatus"
            :key="status"
            class="gap-1 px-3 py-2 text-sm rounded flex items-center justify-center text-black"
            :class="[getStudyDesignOptionsButtonState('followUp', status)]"
            @click="
              studyDesign.followUp === status
                ? updateStudyDesignProperty(
                    'followUp',
                    StudyDesignOptions.default,
                  )
                : updateStudyDesignProperty('followUp', status)
            "
          >
            {{ studyDesignOptionsStatusMap[status as 'yes' | 'no'].text }}
          </button>
        </div>
      </div>

      <div v-if="studyDesign.followUp === StudyDesignOptions.yes">
        <p class="text-sm text-primary font-medium">What there a follow-up?</p>

        <InlineTextInput
          v-model="studyDesign.followUpTime"
          class="h-full w-full"
          editor-classes="h-full"
          :multiline="true"
          @save="updateStudyDesignProperty('followUpTime', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { HttpException } from '@core/exceptions/http.exception'
import { StudyDesignStatus } from '@core/domain/types/studyDesignStatus.type'
import { StudyDesign } from '@core/domain/models/studyDesign.model'
import { reactive, computed } from 'vue'
import { StudyDesignDirection } from '@core/domain/models/studyDesignDirection.model'
import { StudyDesignType } from '@core/domain/models/studyDesignType.model'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import { countries } from '@app/utils/countries'
import FloatingTextInput from '@app/components/Global/Inputs/FloatingTextInput.vue'
import XCircleIcon from '@app/components/Icons/XCircleIcon.vue'
import InlineTextInput from '@app/components/Global/Inputs/InlineTextInput.vue'
import { StudyDesignOptions } from '@core/domain/types/studyDesignOptions.type'
import useExpansionPanel from '@app/composables/use-expansion-panel'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline'

const props = defineProps<{
  study: ReviewItem
  expansionPanel: ReturnType<typeof useExpansionPanel>
}>()

const StudyDesignOptionsStatus = [StudyDesignOptions.yes, StudyDesignOptions.no]

const studyDesignOptionsStatusMap = {
  [StudyDesignOptions.yes]: {
    text: 'Yes',
    abbreviation: 'Y',
  },
  [StudyDesignOptions.no]: {
    text: 'No',
    abbreviation: 'N',
  },
}
const review = injectStrict(ReviewKey)

async function setStudyDesignStatus(
  studyId: number,
  studyDesignStatus: StudyDesignStatus,
) {
  try {
    loading.start()
    await review.setStudyDesignStatus(studyId, studyDesignStatus)
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

const originalStudyDesign = deepClone(
  props.study.studyDesign ?? ({} as StudyDesign),
)

const types = [
  {
    text: 'Interventional',
    value: StudyDesignType.Interventional,
  },
  {
    text: 'Observational',
    value: StudyDesignType.Observational,
  },
]

const directions = [
  {
    text: 'Prospective',
    value: StudyDesignDirection.Prospective,
  },
  {
    text: 'Retrospective',
    value: StudyDesignDirection.Retrospective,
  },
  {
    text: 'Combined prospective and retrospective',
    value: StudyDesignDirection.CombinedProspectiveAndRetrospective,
  },
]

const studyDesign = reactive<StudyDesign>({
  type: originalStudyDesign?.type ?? '',
  direction: originalStudyDesign?.direction ?? '',
  design: originalStudyDesign?.design ?? '',
  controlled: originalStudyDesign?.controlled ?? StudyDesignOptions.default,
  participantBlinding:
    originalStudyDesign?.participantBlinding ?? StudyDesignOptions.default,
  researcherBlinding:
    originalStudyDesign?.researcherBlinding ?? StudyDesignOptions.default,
  analysisBlinding:
    originalStudyDesign?.analysisBlinding ?? StudyDesignOptions.default,
  followUp: originalStudyDesign?.followUp ?? StudyDesignOptions.default,
  followUpTime: originalStudyDesign?.followUpTime ?? '',
  countries: originalStudyDesign?.countries ?? [],
  sites: originalStudyDesign?.sites ?? 0,
  exclusionCriteria: originalStudyDesign.exclusionCriteria ?? [],
  inclusionCriteria: originalStudyDesign.inclusionCriteria ?? [],
  primaryOutcome: originalStudyDesign.primaryOutcome ?? [],
  secondaryOutcome: originalStudyDesign.secondaryOutcome ?? [],
  plannedNumberOfSubjects: originalStudyDesign.plannedNumberOfSubjects ?? 0,
})

const studyDesignStatus = computed(() => {
  return props.study.studyDesignStatus
})

const sitesNumber = computed(() => {
  return studyDesign.sites.toString() ?? ''
})

const plannedNumberOfSubjects = computed(() => {
  return studyDesign.plannedNumberOfSubjects.toString() ?? ''
})

async function updateStudyDesignProperty<Key extends keyof StudyDesign>(
  key: Key,
  value: StudyDesign[Key],
  cb: () => void = () => {},
) {
  studyDesign[key] = value
  await updateStudyDesign(studyDesign, cb)
}

async function updateStudyDesignDirection(
  direction: StudyDesignDirection,
  cb: () => void = () => {},
) {
  if (direction === StudyDesignDirection.Retrospective) {
    studyDesign.participantBlinding = StudyDesignOptions.default
    studyDesign.researcherBlinding = StudyDesignOptions.default
    studyDesign.followUp = StudyDesignOptions.default
  }
  studyDesign.direction = direction
  await updateStudyDesign(studyDesign, cb)
}

const snackbar = useSnackbar()
const loading = useLoading()

async function updateStudyDesign(
  studyDesign: StudyDesign,
  cb: () => void = () => {},
) {
  try {
    loading.start()
    await review.updateStudyDesign(props.study.id, studyDesign)
    Object.assign(originalStudyDesign, deepClone(studyDesign))
    snackbar.success('Study design updated successfully')
    console.log(studyDesign)

    cb()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    Object.assign(studyDesign, deepClone(originalStudyDesign))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function addCountry(countryCode: string) {
  if (!studyDesign.countries.includes(countryCode)) {
    studyDesign.countries.push(countryCode)
    await updateStudyDesign(studyDesign)
  }
}

async function removeCountry(countryCode: string) {
  const index = studyDesign.countries.indexOf(countryCode)

  if (index !== -1) {
    studyDesign.countries.splice(index, 1)
    await updateStudyDesign(studyDesign)
  }
}

async function addCriterion(
  key:
    | 'exclusionCriteria'
    | 'inclusionCriteria'
    | 'primaryOutcome'
    | 'secondaryOutcome',
  criterion: string,
) {
  if (!studyDesign[key].includes(criterion)) {
    studyDesign[key].push(criterion)
    await updateStudyDesign(studyDesign)
  }
}

async function removeCriterion(
  key:
    | 'exclusionCriteria'
    | 'inclusionCriteria'
    | 'primaryOutcome'
    | 'secondaryOutcome',
  criterion: string,
) {
  const index = studyDesign[key].indexOf(criterion)
  if (index > -1) {
    studyDesign[key].splice(index, 1)
    await updateStudyDesign(studyDesign)
  }
}

function getStudyDesignOptionsButtonState<Key extends keyof StudyDesign>(
  key: Key,
  status: string,
) {
  if (studyDesign[key] === status) {
    if (status === StudyDesignOptions.default) {
      return 'bg-slate-200 text-slate-800 fill-slate-800'
    } else if (status === StudyDesignOptions.yes) {
      return 'bg-green-200 text-green-800 fill-green-800'
    } else if (status === StudyDesignOptions.no) {
      return 'bg-red-200 text-red-800 fill-red-800'
    }
  } else {
    return 'bg-slate-200 text-slate-800 fill-slate-800'
  }
}

function deepClone<T>(obj: T): T {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepClone(item)) as unknown as T
  }

  const clonedObj: any = {}
  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      clonedObj[key] = deepClone(obj[key])
    }
  }

  return clonedObj
}
</script>
