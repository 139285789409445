<template>
  <Modal rounded="rounded-2xl">
    <template #activator="{ show }">
      <button class="" @click="show">
        <PenIcon class="w-4 h-4" />
      </button>
    </template>
    <template #content="{ hide }">
      <div class="w-[548px] h-[520px] mt-6">
        <div class="flex justify-end items-end px-6">
          <button class="" @click=";[hide(), reset()]">
            <XIcon class="w-6 h-6" />
          </button>
        </div>
        <div class="space-y-6">
          <div class="flex flex-col justify-center items-center gap-2">
            <UserPlusIcon class="w-10 h-10 text-primary" />
            <p class="text-black text-2xl font-bold leading-6 tracking[-0.4px]">
              edit user
            </p>
          </div>
          <div class="px-16 space-y-4">
            <TextInput
              ref="userFristNameInput"
              v-model="editUserModel.firstName"
              type="text"
              label="First name"
              placeholder="First name"
              :is-required="true"
            />
            <TextInput
              ref="userLastNameInput"
              v-model="editUserModel.lastName"
              type="text"
              label="Last name"
              placeholder="Last name"
              :is-required="true"
            />

            <GenericAutoComplete
              ref="userRoleSelect"
              v-model="editUserModel.role"
              :item-options="{
                displayProperty: 'text',
                valueProperty: 'value',
                filterProperties: ['text', 'value'],
              }"
              label="Role"
              placeholder="Role"
              :items="[
                { text: 'User', value: Role.USER },
                { text: 'Admin', value: Role.ADMIN },
                { text: 'Read Only', value: Role.READ_ONLY },
              ]"
            />

            <div class="flex justify-between">
              <button
                class="w-[191.55px] bg-white text-black border border-primary rounded-lg text-base font-medium py-3 px-10 hover:brightness-110"
                @click=";[reset(), hide()]"
              >
                Cancel
              </button>
              <button
                class="w-[191.55px] bg-primary text-white rounded-lg text-base font-medium py-3 px-10 hover:brightness-110"
                @click="edit(hide)"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { Role } from '@core/domain/types/role.type'
import useUsers from '@app/composables/use-users'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import useLoading from '@app/composables/use-loading'
import UserPlusIcon from '@app/components/Icons/UserPlusIcon.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import XIcon from '@app/components/Icons/XIcon.vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import PenIcon from '@app/components/Icons/PenIcon.vue'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'

interface EditUserModel {
  userId: string
  role: Role
  firstName: string
  lastName: string
}

const props = withDefaults(
  defineProps<{
    data: EditUserModel
  }>(),
  {},
)
const editUserModel = ref<EditUserModel>({
  role: props.data.role,
  firstName: props.data.firstName,
  lastName: props.data.lastName,
  userId: props.data.userId,
})

const snackbar = useSnackbar()
const loading = useLoading()

const users = useUsers()

async function edit(cb: () => void = () => {}) {
  try {
    loading.start()
    await users.edit(editUserModel.value)
    snackbar.show(SnackbarState.SUCCESS, 'User updated successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
    cb()
  }
}
function reset() {
  editUserModel.value = {
    role: props.data.role,
    firstName: props.data.firstName,
    lastName: props.data.lastName,
    userId: props.data.userId,
  }
}
</script>
