<template>
  <div>
    <div class="flex divide-y gap-2 divide-gray-200">
      <StudyActionButton
        v-for="status in peerReviewStatus"
        :key="status"
        :state="getButtonState(status)"
        :is-review-read-only="review.isLocked.value"
        :arrow="false"
        @click="
          study.peerReviewStatus === status
            ? clearStudyPeerReviewStatus(study.id)
            : setStudyPeerReviewStatus(study.id, status)
        "
      >
        {{ peerReviewStatusMap[status as 'yes' | 'no' | 'notApplicable'].text }}
      </StudyActionButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { ButtonState, SnackbarState, StudyState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { HttpException } from '@core/exceptions/http.exception'
import StudyActionButton from '../../StudyActionButton.vue'
import { PeerReviewStatus } from '@core/domain/types/peerReview.type'

const props = defineProps<{
  study: ReviewItem
}>()
const review = injectStrict(ReviewKey)

const peerReviewStatus = [
  PeerReviewStatus.yes,
  PeerReviewStatus.no,
  PeerReviewStatus.notApplicable,
]
const peerReviewStatusMap = {
  [PeerReviewStatus.yes]: {
    text: 'Yes',
    abbreviation: 'Y',
  },
  [PeerReviewStatus.no]: {
    text: 'No',
    abbreviation: 'N',
  },
  [PeerReviewStatus.notApplicable]: {
    text: 'Not applicable',
    abbreviation: 'NA',
  },
}
const snackbar = useSnackbar()
const loading = useLoading()

async function setStudyPeerReviewStatus(
  studyId: number,
  peerReviewStatus: PeerReviewStatus,
  cb: () => void = () => {},
) {
  try {
    loading.start()
    await review.setStudyPeerReviewStatus(studyId, peerReviewStatus)
    snackbar.show(SnackbarState.SUCCESS, 'Peer review status updated')
    cb()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function clearStudyPeerReviewStatus(
  studyId: number,
  cb: () => void = () => {},
) {
  try {
    loading.start()
    await review.clearStudyPeerReviewStatus(studyId)
    snackbar.show(SnackbarState.SUCCESS, 'Peer review status cleared')
    cb()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

function getButtonState(peerReviewStatus: PeerReviewStatus) {
  let buttonState = ButtonState.inactive
  if (props.study.state !== StudyState.INCLUDED) {
    buttonState = ButtonState.disabled
  } else if (props.study.peerReviewStatus === peerReviewStatus) {
    if (peerReviewStatus === PeerReviewStatus.yes) {
      buttonState = ButtonState.green
    } else if (peerReviewStatus === PeerReviewStatus.no) {
      buttonState = ButtonState.red
    } else if (peerReviewStatus === PeerReviewStatus.notApplicable) {
      buttonState = ButtonState.appraised
    }
  }
  return buttonState
}
</script>
