<template>
  <div class="px-4 py-4 space-y-6 container">
    <h3 class="text-2xl text-primary font-medium flex items-center gap-2">
      Evaluators
    </h3>
    <div>
      <table
        aria-describedby="evaluators table"
        class="w-full border-collapse table-fixed"
      >
        <thead>
          <tr>
            <th class="border-b border-black/20 px-4 py-2 text-left w-64">
              Role
            </th>
            <th class="border-b border-black/20 px-4 py-2 text-left">
              Members
            </th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="role in ['Author', 'Reviewer', 'Approver']"
            :key="role"
          >
            <tr>
              <td class="border-b border-black/20 px-4 py-2">
                {{ role }}
              </td>
              <td class="border-b border-black/20 px-4 py-2">
                <div class="flex flex-wrap gap-1 items-center">
                  <div
                    v-for="member in members.filter((m) => m.role === role)"
                    :key="member.user"
                    class="rounded-full bg-blue-100 dark:text-white text-black text-center max-w-fit py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap"
                  >
                    <div
                      class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center"
                    >
                      <p class="h-3 w-3 font-medium leading-3">
                        {{ member.user[0].toUpperCase() }}
                      </p>
                    </div>
                    <div class="leading-none">
                      {{ member.user }}
                    </div>
                    <button @click="removeMemberFromRole(member)">
                      <XIcon class="w-4 h-4" />
                    </button>
                  </div>
                  <EvaluatorsCombobox
                    v-if="!review.isPlanReadonly.value"
                    ref="evaluatorRefs"
                    v-model="clearValues[role]"
                    :role="role"
                    @update:model-value="
                      (user) => {
                        if (user) addMemberToRole(user, role)
                      }
                    "
                    @new-item="addMemberToRole($event, role)"
                  />
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, nextTick, onMounted } from 'vue'
import EvaluatorsCombobox from './EvaluatorsCombobox.vue'

import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import XIcon from '@app/components/Icons/XIcon.vue'

const review = injectStrict(ReviewKey)

const clearValues = ref<{ [key: string]: string }>({})
const members = ref<{ role: string; user: string }[]>([])
const evaluatorRefs = ref<InstanceType<typeof EvaluatorsCombobox>[]>([])

onMounted(() => {
  console.log(evaluatorRefs.value)
})

function addMemberToRole(user: string, role: string) {
  if (!members.value.some((m) => m.user === user && m.role === role))
    members.value.push({ role, user })
  clearValues.value[role] = ''
  nextTick(() => {
    const roleIndex = ['Author', 'Reviewer', 'Approver'].indexOf(role)
    evaluatorRefs.value[roleIndex]?.close()
  })
}

function removeMemberFromRole(member: { role: string; user: string }) {
  const index = members.value.findIndex(
    (m) => m.role === member.role && m.user === member.user,
  )
  members.value.splice(index, 1)
}
</script>
