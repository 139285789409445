import { SortBy, SortOrder, StudyState } from '@app/types'
import { ReviewsService } from '@core/application/reviews.service'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { Search } from '@core/domain/models/search.model'
import { Review } from '@core/domain/models/review.model'
import { Id } from '@core/domain/types/id.type'
import { computed, Ref, ref, InjectionKey, onMounted, onUnmounted } from 'vue'
import useStudiesFiltering from './Execute/SearchAndFilters/use-studies-filtering'
import useStudiesSorting from './Execute/Sort/use-studies-sorting'
import useDisplayOptions from './Execute/DisplayOptions/display-options'
import { OxfordLevelOfEvidenceType } from '@core/domain/types/oxford-level-of-evidence-type'
import { MetaData } from '@core/domain/types/meta-data.type'
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import { Attribute } from '@core/domain/models/attributes.model'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { injectStrict } from '@app/utils/injectStrict'
import { EventHandlerKey } from '@infrastructure/eventHandler/eventHandler'
import { ProjectsServiceKey, ReviewsServiceKey } from '@app/injectionKeys'
import {
  doesStudyContainSearchTerms,
  doesStudyContainState,
} from '@app/utils/studyFiltering'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'

import { ImportStudyPdfJob } from '@core/domain/models/importStudyPdfJob.model'
import { InclusionCriterion } from '@core/domain/models/InclusionCriterion.model'
import { PeerReviewStatus } from '@core/domain/types/peerReview.type'
import { SynthesisPlan } from '@core/domain/models/data-extraction-plan.model'
import { StudyDesignStatus } from '@core/domain/types/studyDesignStatus.type'
import { StudyDesign } from '@core/domain/models/studyDesign.model'

export const ReviewKey: InjectionKey<Awaited<ReturnType<typeof useReview>>> =
  Symbol('Review')

export default async function useReview(reviewId: Id) {
  const review = ref() as Ref<Review>
  const reviewsService: ReviewsService = injectStrict(ReviewsServiceKey)
  const projectsService = injectStrict(ProjectsServiceKey)
  const eventHandler = injectStrict(EventHandlerKey)

  onMounted(() => {
    eventHandler.onReviewSearchCreated(reviewId, () => {
      refresh()
    })
  })
  onUnmounted(() => {
    eventHandler.unregisterReviewSearchHandler(reviewId)
  })

  async function refresh() {
    const foundReview = await reviewsService.findById(reviewId)
    if (!foundReview) throw new Error('review not found')

    review.value = new Review(foundReview)
    const project = await reviewsService.findProject(reviewId)
    review.value.project = project
    runningStudiesPdfImports.value =
      await reviewsService.findRunningStudiesPdfImports(reviewId)
    if (runningStudiesPdfImports.value.length !== 0) {
      pollUntilPdfImportsFinished()
    }

    filtering.init(review.value)

    synthesisPlan.value = review.value.plan?.synthesisPlan ?? {
      attributesStructure: [],
    }
  }

  const filtering = useStudiesFiltering()
  const sorting = useStudiesSorting()

  const inclusionCriteria = computed({
    get: () => review.value.plan?.inclusionCriteria,
    set: (v) => {
      if (v !== inclusionCriteria.value) inclusionCriteria.value = v
    },
  })

  const synthesisPlan = ref<SynthesisPlan>({
    attributesStructure: [],
  })

  const searchesBySource = computed<{ [key: string]: Search[] }>(() => {
    const builtInSources = Object.values(BuiltInImportSourceId)

    const plannedSources =
      review.value.plan?.importPlan.importSources
        ?.map((source) => source.id)
        .filter(
          (id) => !builtInSources.includes(id as BuiltInImportSourceId),
        ) ?? []

    const allSources = [...builtInSources, ...plannedSources]

    return allSources.reduce(
      (acc, sourceId) => {
        acc[sourceId] =
          review.value?.searches?.filter((s) => s.source.id === sourceId) ?? []
        return acc
      },
      {} as { [key: string]: Search[] },
    )
  })

  const areInvalidStudiesDisplayed = ref(false)

  const runningStudiesPdfImports = ref<ImportStudyPdfJob[]>([])

  const runningStudiesPdfImportsMap = computed<Map<number, ImportStudyPdfJob>>(
    () => {
      const result: Map<number, ImportStudyPdfJob> = new Map()
      runningStudiesPdfImports.value.forEach((job) => {
        result.set(job.studyId, job)
      })
      return result
    },
  )

  const isPlanReadonly = computed(
    () =>
      (review.value.plan?.lockState === ReviewLockState.LOCKED &&
        review.value.project?.useReviewsPlanLocking) ||
      review.value.isLocked ||
      review.value.isArchived,
  )

  const isLocked = computed(() => review.value.isLocked)

  const isArchived = computed(() => review.value.isArchived)

  const isReviewReadonly = computed(
    () => review.value.isLocked || review.value.isArchived,
  )

  let intervalPdfJobs: NodeJS.Timeout | null = null

  function pollUntilPdfImportsFinished() {
    const intervalDuration = 10000
    if (intervalPdfJobs && runningStudiesPdfImports.value.length > 0) return
    intervalPdfJobs = setInterval(async () => {
      if (runningStudiesPdfImports.value.length === 0 && intervalPdfJobs) {
        clearInterval(intervalPdfJobs)
        intervalPdfJobs = null
      } else {
        runningStudiesPdfImports.value =
          await reviewsService.findRunningStudiesPdfImports(reviewId)
      }
    }, intervalDuration)
  }

  await refresh()

  const displayOptions = useDisplayOptions()

  const filteredStudies = computed(() => {
    let studies = review.value.studies.filter((study) => {
      return (
        (doesStudyContainSearchTerms({
          study,
          terms: filtering.highlightOnly.value ? [] : filtering.terms.value,
        }) &&
          doesStudyContainState({
            study,
            states: Object.keys(filtering.filters.value).filter(
              (f) => !!filtering.filters.value[f as StudyState],
            ) as StudyState[],
          }) &&
          // Filter by Search
          filtering.searchFilters.value[
            `search ${review.value.getSearchIndex(study) + 1}`
          ] &&
          // Filter by source
          filtering.importSourceFilters.value[study.search?.source?.id ?? ''] &&
          // Filter by full text screening
          (study.state !== StudyState.EXCLUDED ||
            (study.fullTextScreening &&
              filtering.fullTextExclusionCriteriaFilter.value[
                study.fullTextScreening
              ]) ||
            // Filter by title and abstract screening
            (study.state === StudyState.EXCLUDED &&
              study.titleAndAbstractScreening &&
              filtering.titleAndAbstractExclusionCriteriaFilter.value[
                study.titleAndAbstractScreening
              ]))) ||
        (filtering.filters.value[StudyState.DUPLICATE] &&
          parentStudyIds.value.has(study.id)) ||
        (filtering.filters.value[StudyState.QUARANTINED] &&
          maybeParentStudyIds.value.has(study.id))
      )
    })

    if (sorting.sort.value.by !== SortBy.None) {
      studies = studies.sort((a, b) => {
        let value1: string | number
        let value2: string | number
        switch (sorting.sort.value.by) {
          case SortBy.Title:
            value1 = a?.metadata?.title?.toLowerCase() ?? ''
            value2 = b?.metadata?.title?.toLowerCase() ?? ''
            break
          case SortBy.Author:
            value1 = a?.metadata?.authors?.[0]?.toLowerCase() ?? ''
            value2 = b?.metadata?.authors?.[0]?.toLowerCase() ?? ''
            break
          case SortBy.Year:
            value1 = a?.metadata?.publishYear ?? 0
            value2 = b?.metadata?.publishYear ?? 0
            break
          default:
            value1 = 0
            value2 = 0
        }
        if (value1 === value2) return 0
        if (sorting.sort.value.order === SortOrder.Ascending) {
          return value1 > value2 ? 1 : -1
        } else {
          return value1 < value2 ? 1 : -1
        }
      })
    }

    const repositionStudiesConsideringParents = (
      studies: ReviewItem[],
    ): ReviewItem[] => {
      const childIds = new Set(
        studies
          .filter((study) => study.parentStudyId ?? study.maybeParentStudyId)
          .map((study) => study.id),
      )

      const result = studies.filter((study) => !childIds.has(study.id))

      const addChildren = (study: ReviewItem): ReviewItem[] => {
        const children = studies.filter(
          (s) =>
            s.parentStudyId === study.id || s.maybeParentStudyId === study.id,
        )
        if (children.length > 0) {
          return [study, ...children.flatMap(addChildren)]
        } else {
          return [study]
        }
      }

      return result.flatMap(addChildren)
    }

    return repositionStudiesConsideringParents(studies)
  })

  const parentStudyIds = computed(() => {
    return new Set(
      review.value.studies
        .map((study) => study.parentStudyId)
        .filter((id) => id !== null),
    )
  })

  const maybeParentStudyIds = computed(() => {
    return new Set(
      review.value.studies
        .map((study) => study.maybeParentStudyId)
        .filter((id) => id !== null),
    )
  })

  const markStudyAsDuplicate = async (
    studyId: Id,
    parentStudyId: Id,
  ): Promise<void> => {
    if (!review.value) throw Error('search not found')
    await reviewsService.markStudyAsDuplicate(
      review.value.id,
      studyId,
      parentStudyId,
    )
    const study = review.value.studies.find((s) => s.id === studyId)!
    study.markAsDuplicate(parentStudyId)
  }

  const markStudyAsNotDuplicate = async (studyId: Id): Promise<void> => {
    if (!review.value) throw Error('search not found')
    await reviewsService.markStudyAsNotDuplicate(review.value?.id, studyId)
    const study = review.value.studies.find((s) => s.id === studyId)!
    study.markAsNotDuplicate()
  }

  const setStudyTitleAndAbstractScreening = async (
    studyId: Id,
    key: string,
  ) => {
    if (!review.value) throw Error('review not found')
    await reviewsService.setStudyTitleAndAbstractScreening(
      review.value.id,
      studyId,
      key,
    )

    const study = review.value.studies.find((s) => s.id === studyId)!
    study.titleAndAbstractScreening = key
    review.value.updateStudy(studyId, { titleAndAbstractScreening: key })
  }

  const clearStudyTitleAndAbstractScreening = async (studyId: Id) => {
    if (!review.value) throw Error('search not found')
    await reviewsService.clearStudyTitleAndAbstractScreening(
      review.value.id,
      studyId,
    )
    const study = review.value.studies.find((s) => s.id === studyId)!
    study.titleAndAbstractScreening = ''
    review.value.updateStudy(studyId, { titleAndAbstractScreening: '' })
  }

  const setStudyFullTextScreening = async (
    studyId: Id,
    fullTextScreening: string,
  ) => {
    if (!review.value) throw Error('search not found')
    await reviewsService.setStudyFullTextScreening(
      review.value.id,
      studyId,
      fullTextScreening,
    )
    const study = review.value.studies.find((s) => s.id === studyId)!
    study.fullTextScreening = fullTextScreening
    review.value.updateStudy(studyId, { fullTextScreening })
  }

  const clearStudyFullTextScreening = async (studyId: Id) => {
    if (!review.value) throw Error('search not found')
    await reviewsService.clearStudyFullTextScreening(review.value.id, studyId)
    review.value.updateStudy(studyId, {
      fullTextScreening: '',
    })
  }

  const favoriteStudy = async (studyId: Id) => {
    if (!review.value) throw new Error('no search set')
    await reviewsService.favoriteStudy(review.value.id, studyId)
    review.value.updateStudy(studyId, {
      isFavorite: true,
    })
  }

  const unfavoriteStudy = async (studyId: Id) => {
    if (!review.value) throw new Error('no search set')
    await reviewsService.unfavoriteStudy(review.value.id, studyId)
    review.value.updateStudy(studyId, {
      isFavorite: false,
    })
  }

  const removeSearch = async (searchId: number) => {
    if (!review.value) throw new Error('no review set')
    await reviewsService.removeSearch(review.value.id, searchId)
    const foundReview = await reviewsService.findById(review.value.id)
    if (!foundReview) throw new Error('review not found')
  }

  async function uploadStudyPdfFile(studyId: Id, file: File) {
    if (!review.value) throw new Error('no review set')
    const pdfFile = await reviewsService.uploadStudyPdfFile(
      review.value.id,
      studyId,
      file,
    )
    const study = review.value.studies.find((s) => s.id === studyId)!
    review.value.updateStudy(studyId, {
      ...study,
      pdfFile,
    })
  }

  async function getStudyPdfFile(studyId: Id) {
    if (!review.value) throw new Error('no review set')
    return reviewsService.getStudyPdfFile(review.value.id, studyId)
  }

  async function downloadSearchCitationFile(searchId: Id) {
    if (!review.value) throw new Error('no review set')
    return reviewsService.downloadSearchCitationFile(review.value.id, searchId)
  }

  async function deleteStudyPdfFile(studyId: Id) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.deleteStudyPdfFile(review.value.id, studyId)
    review.value.updateStudy(studyId, {
      pdfFile: undefined,
      pdfContent: undefined,
    })
  }

  async function downloadPdfZip() {
    if (!review.value) throw new Error('no review set')
    return reviewsService.downloadPdfZip(review.value.id)
  }

  async function editStudyAbstract(studyId: Id, abstract: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.editStudyAbstract(review.value.id, studyId, abstract)
    const study = review.value.studies.find((a) => a.id === studyId)
    review.value.updateStudy(studyId, {
      metadata: { ...study?.metadata, abstract },
    })
  }

  async function submitComplaint(
    studyId: Id,
    data: {
      body: string
      fromAddress: string
      fromName: string
      subject: string
      to: string
    },
  ) {
    if (!review.value) throw new Error('no review set')
    const complaintDate = await reviewsService.submitComplaint(
      review.value.id,
      studyId,
      data,
    )

    review.value.updateStudy(studyId, {
      complaintDate,
    })
  }

  async function addFullTextCriterion(criterion: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.addFullTextCriterion(review.value.id, criterion)
    refresh()
  }

  async function enableTitleAndAbstractScreening() {
    if (!review.value) throw new Error('no review set')
    await reviewsService.enableTitleAndAbstractScreening(review.value.id)
    refresh()
  }

  async function disableTitleAndAbstractScreening() {
    if (!review.value) throw new Error('no review set')
    await reviewsService.disableTitleAndAbstractScreening(review.value.id)
    refresh()
  }

  async function deletFullTextCriterion(criterion: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.deleteFullTextCriterion(review.value.id, criterion)
    refresh()
  }

  async function addTitleAndAbstractCriterion(criterion: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.addTitleAndAbstractCriterion(
      review.value.id,
      criterion,
    )
    refresh()
  }

  async function deleteTitleAndAbstractCriterion(criterion: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.deleteTitleAndAbstractCriterion(
      review.value.id,
      criterion,
    )
    refresh()
  }

  const planScreening = async (data: {
    titleAndAbstractCriteria: string[]
    fullTextCriteria: string[]
  }) => {
    if (!review.value) throw new Error('no review set')
    await reviewsService.planScreening(review.value.id, data)
  }

  async function removeImportSourceFromPlan(importSourceId: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.removeImportSourceFromPlan(
      review.value.id,
      importSourceId,
    )
    review.value.removeImportSource(importSourceId)
  }

  async function enableStudiesAppraisalImdrfMdce2019() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.enableStudiesAppraisalImdrfMdce2019(review.value.id)
    review.value.plan.appraisalPlan.isImdrfMdce2019Applicable = true
  }

  async function enableStudiesAppraisalOxfordLevelOfEvidence() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.enableStudiesAppraisalOxfordLevelOfEvidence(
      review.value.id,
    )
    review.value.plan.appraisalPlan.isOxfordLevelOfEvidenceApplicable = true
  }
  async function enablePicoInclusionCriteria() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.enablePicoInclusionCriteria(review.value.id)
    review.value.plan.inclusionCriteria.isPicoInclusionCriteriaApplicable = true
  }

  async function disablePicoInclusionCriteria() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.disablePicoInclusionCriteria(review.value.id)
    review.value.plan.inclusionCriteria.isPicoInclusionCriteriaApplicable =
      false
  }

  async function enableStudiesAppraisalPeerReviewStatus() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.enableStudiesAppraisalPeerReviewStatus(review.value.id)
    review.value.plan.appraisalPlan.isPeerReviewStatusApplicable = true
  }

  async function disableStudiesAppraisal() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.disableStudiesAppraisal(review.value.id)
    review.value.plan.appraisalPlan.isImdrfMdce2019Applicable = false
  }

  async function disableStudiesAppraisalOxfordLevelOfEvidence() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.disableStudiesAppraisalOxfordLevelOfEvidence(
      review.value.id,
    )
    review.value.plan.appraisalPlan.isOxfordLevelOfEvidenceApplicable = false
  }

  async function disableStudiesAppraisalPeerReviewStatus() {
    if (!review.value) throw new Error('no review set')
    if (!review.value.plan?.appraisalPlan) throw new Error('')
    await reviewsService.disableStudiesAppraisalPeerReviewStatus(
      review.value.id,
    )
    review.value.plan.appraisalPlan.isPeerReviewStatusApplicable = false
  }

  async function planSearch(plan: {
    screening: {
      titleAndAbstractCriteria: string[]
      fullTextCriteria: string[]
    }
  }) {
    await planScreening({
      titleAndAbstractCriteria: plan.screening.titleAndAbstractCriteria?.filter(
        (c) => c !== '',
      ),
      fullTextCriteria: plan.screening?.fullTextCriteria.filter(
        (c) => c !== '',
      ),
    })
    await refresh()
  }

  async function appraiseStudy(
    studyId: Id,
    appraisal: { criterionId: string; gradeId: string },
  ) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.appraiseStudy(review.value?.id, studyId, appraisal)
    const study = review.value.studies.find((a) => a.id === studyId)
    review.value.updateStudy(studyId, {
      appraisal: {
        ...study?.appraisal,
        [appraisal.criterionId]: appraisal.gradeId,
      },
    })
  }
  async function setOxfordLevelOfEvidenceStudy(
    studyId: Id,
    oxfordLevelOfEvidence: OxfordLevelOfEvidenceType,
  ) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.setOxfordLevelOfEvidenceStudy(
      review.value?.id,
      studyId,
      oxfordLevelOfEvidence,
    )
    review.value.updateStudy(studyId, {
      oxfordLevelOfEvidence: oxfordLevelOfEvidence,
    })
  }

  async function setStudyPeerReviewStatus(
    studyId: Id,
    peerReviewStatus: PeerReviewStatus,
  ) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.setStudyPeerReviewStatus(
      review.value?.id,
      studyId,
      peerReviewStatus,
    )
    review.value.updateStudy(studyId, {
      peerReviewStatus: peerReviewStatus,
    })
  }

  async function setStudyDesignStatus(
    studyId: Id,
    studyDesignStatus: StudyDesignStatus,
  ) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.setStudyDesignStatus(
      review.value?.id,
      studyId,
      studyDesignStatus,
    )
    review.value.updateStudy(studyId, {
      studyDesignStatus: studyDesignStatus,
    })
  }

  async function clearStudyAppraisal(studyId: Id, criterionId: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.clearStudiesAppraisal(
      review.value.id,
      studyId,
      criterionId,
    )
    const study = review.value.studies.find((a) => a.id === studyId)
    review.value.updateStudy(studyId, {
      appraisal: {
        ...study?.appraisal,
        [criterionId]: '',
      },
    })
  }

  async function clearStudyOxfordLevelOfEvidence(studyId: Id) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.cleaStudyOxfordLevelOfEvidence(
      review.value.id,
      studyId,
    )
    review.value.updateStudy(studyId, {
      oxfordLevelOfEvidence: '',
    })
  }

  async function clearStudyPeerReviewStatus(studyId: Id) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.clearStudyPeerReviewStatus(review.value.id, studyId)
    review.value.updateStudy(studyId, {
      peerReviewStatus: PeerReviewStatus.default,
    })
  }

  async function updateStudy(studyId: Id, metadata: MetaData) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.updateStudy(review.value.id, studyId, metadata)
    review.value.updateStudy(studyId, { metadata })
  }

  async function addAttributeStructure(
    reviewId: Id,
    attribute: Partial<AttributeStructure>,
  ) {
    const data = await reviewsService.addAttributeStructure(reviewId, {
      label: attribute.label ?? '',
      question: attribute.question ?? '',
      order: attribute.order ?? 0,
    })
    synthesisPlan.value.attributesStructure = [
      ...synthesisPlan.value.attributesStructure,
      data,
    ]
  }

  async function addMultipleAttributesStructure(
    reviewId: Id,
    attributes: Omit<AttributeStructure, 'id'>[],
  ) {
    const data = await reviewsService.addMultipleAttributesStructure(
      reviewId,
      attributes,
    )
    synthesisPlan.value.attributesStructure = [
      ...synthesisPlan.value.attributesStructure,
      ...data,
    ]
  }

  async function deleteAttributeStructure(
    reviewId: Id,
    attributeStructureId: string,
  ) {
    await reviewsService.deleteAttributeStructure(
      reviewId,
      attributeStructureId,
    )
  }

  async function editAttributeStructure(
    reviewId: Id,
    updatedAttribute: AttributeStructure,
  ) {
    await reviewsService.editAttributeStructure(reviewId, updatedAttribute)
    await refresh()
  }

  async function updateStudySynthesisAttribute(
    reviewId: Id,
    studyId: Id,
    attribute: Attribute,
  ) {
    await reviewsService.updateStudySynthesisAttribute(reviewId, studyId, {
      id: attribute.attributeStructureId,
      value: attribute.value,
    })
    const study = review.value.studies.find((s) => s.id === studyId)!
    const foundAttributeIndex =
      study.synthesis?.attributes?.findIndex(
        (a) => a.attributeStructureId === attribute.attributeStructureId,
      ) ?? -1
    if (foundAttributeIndex !== -1) {
      study.synthesis.attributes[foundAttributeIndex] = { ...attribute }
    } else {
      study.synthesis.attributes.push(attribute)
    }
    review.value.updateStudy(studyId, study)
  }

  async function lockPlan(reviewId: Id) {
    await reviewsService.lockPlan(reviewId)
    await refresh()
  }

  async function unlockPlan(reviewId: Id) {
    await reviewsService.unlockPlan(reviewId)
    await refresh()
  }

  async function addCustomImportSourceToPlan(data: {
    id?: string
    name: string
    url: string
    type: ImportSourceType
    fullName?: string
    description?: string
  }): Promise<string> {
    const sourceId = await reviewsService.addCustomImportSourceToPlan(
      reviewId,
      data,
    )
    await refresh()
    return sourceId
  }

  async function importRisSearch({
    citationFiles,
    date,
    query,
    filters,
    importSourceId,
  }: {
    query?: string
    filters?: string
    citationFiles: File[]
    date: string
    importSourceId: string
  }) {
    await reviewsService.importRisSearch({
      reviewId,
      citationFiles,
      date,
      query,
      filters,
      importSourceId,
    })
    await refresh()
  }

  async function importCitationSearch({
    citationFiles,
    parentStudyId,
  }: {
    citationFiles: File[]
    parentStudyId: number
  }) {
    await reviewsService.importCitationSearch({
      reviewId,
      citationFiles,
      parentStudyId,
    })
    await refresh()
  }

  const currentAttributesStructure = computed(() => {
    return review.value.plan?.synthesisPlan.attributesStructure ?? []
  })

  async function answerStudyQuestion(
    studyId: number,
    question: string,
  ): Promise<string> {
    if (!review.value) throw new Error('no review set')
    return reviewsService.answerStudyQuestion(studyId, question)
  }

  async function addPicoInclusionCriterion(newCriterion: {
    criterionType: InclusionCriterion
    criterion: string
  }) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.addPicoInclusionCriterion(
      review.value.id,
      newCriterion,
    )
    refresh()
  }

  async function deletePicoInclusionCriterion(criterionDetails: {
    criterionType: InclusionCriterion
    criterion: string
  }) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.deletePicoInclusionCriterion(
      review.value.id,
      criterionDetails,
    )
    refresh()
  }

  async function addInclusionCriterion(criterion: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.addInclusionCriterion(review.value.id, criterion)
    refresh()
  }

  async function deleteInclusionCriterion(criterion: string) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.deleteInclusionCriterion(review.value.id, criterion)
    refresh()
  }

  async function addAppraisalCriterionAnswer(data: {
    appraisalCriterionId: string
    answer: string
  }) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.addAppraisalCriteria(review.value.id, data)
    refresh()
  }
  async function deleteAppraisalCriterionAnswer(data: {
    answerId: string
    appraisalCriterionId: string
  }) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.deleteAppraisalCriterionAnswer(review.value.id, data)
    refresh()
  }
  async function lockReview(reviewId: Id) {
    await reviewsService.lock(reviewId)
    await refresh()
  }

  async function unlockReview(reviewId: Id) {
    await reviewsService.unlock(reviewId)
    await refresh()
  }

  async function updateCslStyle(cslStyle: string) {
    if (!review.value.project?.id)
      throw new Error('something went wrong, cannot find project id')
    await projectsService.updateCslStyle(review.value.project?.id, cslStyle)
  }

  async function updateStudyDesign(studyId: Id, studyDesign: StudyDesign) {
    if (!review.value) throw new Error('no review set')
    await reviewsService.updateStudyDesign(
      review.value?.id,
      studyId,
      studyDesign,
    )
    review.value.updateStudy(studyId, { studyDesign: studyDesign })
  }

  async function addAuthorToReview(reviewId: Id, authorId: string) {
    await reviewsService.addAuthor(reviewId, authorId)
    await refresh()
  }

  async function addReviewerToReview(reviewId: Id, reviewerId: string) {
    await reviewsService.addReviewer(reviewId, reviewerId)
    await refresh()
  }

  async function addApproverToReview(reviewId: Id, approverId: string) {
    await reviewsService.addApprover(reviewId, approverId)
    await refresh()
  }

  async function addExternalApproverToReview(
    reviewId: Id,
    externalApproverName: string,
  ) {
    await reviewsService.addExternalApprover(reviewId, externalApproverName)
    await refresh()
  }

  async function removeAuthorFromReview(reviewId: Id, authorId: string) {
    await reviewsService.removeAuthor(reviewId, authorId)
    await refresh()
  }

  async function removeReviewerFromReview(reviewId: Id, reviewerId: string) {
    await reviewsService.removeReviewer(reviewId, reviewerId)
    await refresh()
  }

  async function removeApproverFromReview(reviewId: Id, approverId: string) {
    await reviewsService.removeApprover(reviewId, approverId)
    await refresh()
  }

  async function removeExternalApproverFromReview(
    reviewId: Id,
    externalApproverName: string,
  ) {
    await reviewsService.removeExternalApprover(reviewId, externalApproverName)
    await refresh()
  }

  return {
    refresh,
    markStudyAsDuplicate,
    markStudyAsNotDuplicate,
    setStudyTitleAndAbstractScreening,
    clearStudyTitleAndAbstractScreening,
    setStudyFullTextScreening,
    clearStudyFullTextScreening,
    removeSearch,
    planSearch,
    enableStudiesAppraisalOxfordLevelOfEvidence,
    removeImportSourceFromPlan,
    favoriteStudy,
    unfavoriteStudy,
    uploadStudyPdfFile,
    getStudyPdfFile,
    deleteStudyPdfFile,
    downloadPdfZip,
    downloadSearchCitationFile,
    editStudyAbstract,
    enableStudiesAppraisalImdrfMdce2019,
    disableStudiesAppraisalOxfordLevelOfEvidence,
    enableStudiesAppraisalPeerReviewStatus,
    disableStudiesAppraisalPeerReviewStatus,
    disableStudiesAppraisal,
    submitComplaint,
    appraiseStudy,
    setOxfordLevelOfEvidenceStudy,
    clearStudyAppraisal,
    clearStudyOxfordLevelOfEvidence,
    updateStudy,
    addAttributeStructure,
    deleteAttributeStructure,
    editAttributeStructure,
    updateStudySynthesisAttribute,
    lockPlan,
    addCustomImportSourceToPlan,
    importRisSearch,
    addFullTextCriterion,
    addTitleAndAbstractCriterion,
    deletFullTextCriterion,
    deleteTitleAndAbstractCriterion,
    enableTitleAndAbstractScreening,
    disableTitleAndAbstractScreening,
    unlockPlan,
    importCitationSearch,
    answerStudyQuestion,
    addMultipleAttributesStructure,
    addPicoInclusionCriterion,
    deletePicoInclusionCriterion,
    addAppraisalCriterionAnswer,
    deleteAppraisalCriterionAnswer,
    setStudyPeerReviewStatus,
    clearStudyPeerReviewStatus,
    updateCslStyle,
    setStudyDesignStatus,
    updateStudyDesign,
    enablePicoInclusionCriteria,
    disablePicoInclusionCriteria,
    addInclusionCriterion,
    deleteInclusionCriterion,
    addAuthorToReview,
    addReviewerToReview,
    addApproverToReview,
    addExternalApproverToReview,
    removeAuthorFromReview,
    removeReviewerFromReview,
    removeApproverFromReview,
    removeExternalApproverFromReview,
    isLocked,
    isPlanReadonly,
    isArchived,
    isReviewReadonly,
    entity: review,
    filteredStudies,
    filtering,
    sorting,
    displayOptions,
    areInvalidStudiesDisplayed,
    searchesBySource,
    inclusionCriteria,
    currentAttributesStructure,
    parentStudyIds,
    maybeParentStudyIds,
    runningStudiesPdfImportsMap,
    synthesisPlan,
    lockReview,
    unlockReview,
  }
}
