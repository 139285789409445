<template>
  <div class="w-full px-12 py-5 container">
    <TextInput
      v-model="searchQuery"
      placeholder="Search"
      class="w-full"
      @input="onSearch"
    />
    <div v-if="!loading.isLoading.value">
      <div v-if="totalPages > 1" class="mt-6 flex justify-between items-center">
        <button
          :disabled="currentPage === 1"
          class="px-4 py-2 bg-gray-300 rounded disabled:bg-gray-100"
          @click="prevPage"
        >
          Previous
        </button>
        <span
          >Page {{ currentPage }} of {{ totalPages }} - total
          {{ totalResults }}
        </span>
        <button
          :disabled="currentPage === totalPages"
          class="px-4 py-2 bg-gray-300 rounded disabled:bg-gray-100"
          @click="nextPage"
        >
          Next
        </button>
      </div>
      <div v-if="loading.isLoading.value"></div>
      <ul v-else-if="results.length" class="space-y-2 mt-4">
        <li
          v-for="article in results"
          :key="article._formatted.id"
          class="p-4 b border rounded"
        >
          <strong>title: </strong>
          <a target="_blank" :href="article.link" class="text-primary">
            <p v-html="article._formatted.title" />
          </a>
          <div class="flex items-center gap-4 text-sm pb-2 pt-1">
            <p class="text-primary" v-html="article._formatted.source" />
            <div v-html="article._formatted.date" />
          </div>
          <div class="py-2">
            <strong> Summary: </strong>
            <p v-html="article._formatted.summary" />
          </div>
          <div>
            <strong> Full text: </strong>
            <p v-html="article._formatted.fulltext" />
          </div>
        </li>
      </ul>
      <div v-else class="mt-4">No results found</div>
      <div v-if="totalPages > 1" class="mt-6 flex justify-between items-center">
        <button
          :disabled="currentPage === 1"
          class="px-4 py-2 bg-gray-300 rounded disabled:bg-gray-100"
          @click="prevPage"
        >
          Previous
        </button>
        <span
          >Page {{ currentPage }} of {{ totalPages }} - total
          {{ totalResults }}
        </span>
        <button
          :disabled="currentPage === totalPages"
          class="px-4 py-2 bg-gray-300 rounded disabled:bg-gray-100"
          @click="nextPage"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { ref, onMounted, watch } from 'vue'
import { createDebounce } from '@app/utils/debounce'
import { MeiliSearch } from 'meilisearch'
import useLoading from '@app/composables/use-loading'

const client = new MeiliSearch({
  host: location.protocol + '//' + location.host + '/fsn-api',
})
type Article = {
  id: string
  title: string
  date: string
  link: string
  summary: string
  source: string
  fulltext?: string
  type: string
  _formatted: Omit<Article, '_formatted'>
}

const searchQuery = ref('')
const results = ref<Article[]>([])
const currentPage = ref(1)
const totalPages = ref(1)
const limit = ref(10)
const totalResults = ref(0)

watch(searchQuery, () => {
  currentPage.value = 1
})

const debounceSearch = createDebounce()

const loading = useLoading()

async function search() {
  loading.start()
  try {
    const offset = (currentPage.value - 1) * limit.value
    const highlightPreTag = '<mark class="bg-yellow-400">'
    const highlightPostTag = '</mark>'
    const searchResults = await client.multiSearch({
      federation: {
        limit: limit.value,
        offset: offset,
      },
      queries: [
        {
          indexUid: 'bfarm',
          q: searchQuery.value,
          highlightPreTag,
          attributesToHighlight: ['*'],
          highlightPostTag,
          matchingStrategy: 'all',
        },
        {
          indexUid: 'ansm',
          q: searchQuery.value,
          highlightPreTag,
          attributesToHighlight: ['*'],
          highlightPostTag,
          matchingStrategy: 'all',
        },
        {
          indexUid: 'sara',
          q: searchQuery.value,
          highlightPreTag,
          attributesToHighlight: ['*'],
          highlightPostTag,
          matchingStrategy: 'all',
        },
        {
          indexUid: 'mhra',
          q: searchQuery.value,
          highlightPreTag,
          attributesToHighlight: ['*'],
          highlightPostTag,
          matchingStrategy: 'all',
        },
      ],
    })

    results.value = searchResults.hits as unknown as Article[]
    totalResults.value = searchResults.estimatedTotalHits ?? 0
    totalPages.value = Math.ceil(totalResults.value / limit.value)
  } catch (error) {
    console.error('Error searching documents:', error)
  } finally {
    loading.stop()
  }
}

function onSearch() {
  debounceSearch(search)
}

function nextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value += 1
    search()
  }
}

function prevPage() {
  if (currentPage.value > 1) {
    currentPage.value -= 1
    search()
  }
}

onMounted(() => {
  search()
})
</script>
