<template>
  <div
    v-if="!shouldRender"
    v-intersection-observer="onIntersectionObserver"
    class="flex justify-between gap-2 px-2 py-1 items-center w-full h-[90px] col-span-full"
  >
    <div class="w-full gap-2 min-w-0 flex">
      <div class="w-full">
        <h1 class="text-md truncate text-white">Loading</h1>
        <p class="text-gray-500">please wait</p>
      </div>
    </div>
  </div>
  <template v-else>
    <ArticleDetails
      v-if="study.type === ReviewItemType.Article"
      :article="study as Article"
      :is-metadata-expanded="props.isMetadataExpanded"
      :expansion-panel="expansionPanel"
    />
    <IncidentDetails
      v-else-if="study.type === ReviewItemType.Incident"
      :incident="study as Incident"
      :expansion-panel="expansionPanel"
    />
    <GuidanceDetails
      v-else-if="study.type === ReviewItemType.Guidance"
      :guidance="study as Guidance"
      :expansion-panel="expansionPanel"
    />
    <div
      v-if="screeningStage"
      class="border border-[#D9E4EA] py-3 dark:border-[#345C7C] px-2 items-center shrink-0 max-w-[399px]"
      :class="{
        'bg-azureish-white/25': isHoveredOver,
      }"
      @mouseover="onMouseOver"
      @mouseout="onMouseOut"
    >
      <div class="flex gap-2">
        <StudyDuplicationButton
          :study="study"
          :is-loading="isLoading"
          @mark-as-duplicate="markStudyAsDuplicate"
          @mark-as-not-duplicate="markStudyAsNotDuplicate"
        />
        <StudyTitleAndAbstractScreeningButton
          v-if="
            review.entity.value?.plan?.screeningPlan?.titleAndAbstractCriteria
              .length ?? 0 > 0
          "
          :study="study"
          :is-loading="isLoading"
          @clear-title-and-abstract-screening="clearTitleAndAbstractScreening"
          @set-title-and-abstract-screening="setTitleAndAbstractScreening"
        />
        <StudyFullTextScreeningButton
          :study="study"
          :is-loading="isLoading"
          @clear-full-text-screening="clearFullTextScreening"
          @set-full-text-screening="setFullTextScreening"
        />
      </div>
      <div v-if="expansionPanel.isShown.value" class="mb-4 mt-8">
        <div class="w-full">
          <span
            v-if="study.state === StudyState.INCLUDED"
            class="bg-primary-green px-2 py-1 text-black rounded-md text-sm"
          >
            Included
          </span>
          <div
            v-else-if="study.isDuplicate"
            class="w- truncate bg-red-500 px-2 py-1 rounded-md text-white text-xs"
          >
            Duplicate of (ID:
            {{ duplicationParentStudy?.id }})
          </div>

          <span
            v-else-if="exclusionCriterion"
            class="bg-red-500 px-2 py-1 rounded-md text-white text-xs"
          >
            {{ exclusionCriterion }}
          </span>
          <span
            v-else
            class="bg-neutral-900 px-2 py-1 rounded-md text-white text-xs"
          >
            Unscreened
          </span>
        </div>
      </div>
    </div>
    <StudyDesign
      v-if="props.studyDesignStage"
      :study="study"
      :expansion-panel="expansionPanel"
    />
    <StudyAppraisal :study="study" />
    <AttributeExtraction
      v-if="props.synthesisStage"
      v-model="isHoveredOver"
      :is-expanded="expansionPanel.isShown.value"
      :study="study"
    />
  </template>
</template>

<script lang="ts" setup>
import useExpansionPanel from '@app/composables/use-expansion-panel'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState, StudyState } from '@app/types'
import { ReviewKey } from '@app/views/Review/use-review'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { Id } from '@core/domain/types/id.type'
import { hideAll } from 'tippy.js'
import { computed, ref } from 'vue'
import StudyAppraisal from './StudyAppraisal/StudyAppraisal.vue'
import StudyDuplicationButton from './StudyDuplicationButton.vue'
import StudyFullTextScreeningButton from './StudyFullTextScreeningButton.vue'
import StudyTitleAndAbstractScreeningButton from './StudyTitleAndAbstractScreeningButton.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { injectStrict } from '@app/utils/injectStrict'
import AttributeExtraction from '@app/views/Review/Execute/Studies/AttributeExtraction/AttributeExtraction.vue'
import { vIntersectionObserver } from '@vueuse/components'
import StudyDesign from './StudyDesign/StudyDesign.vue'
import ArticleDetails from './ReviewItemDetails/Article.vue'
import IncidentDetails from './ReviewItemDetails/Incident.vue'
import GuidanceDetails from './ReviewItemDetails/Guidance.vue'

import { ReviewItemType } from '@core/domain/types/reviewItemType.type'
import { Article } from '@core/domain/models/article.model'
import { Incident } from '@core/domain/models/incident.model'
import { Guidance } from '@core/domain/models/guidance.model'

const review = injectStrict(ReviewKey)

const isHoveredOver = ref(false)

function onMouseOver() {
  isHoveredOver.value = true
}
function onMouseOut() {
  isHoveredOver.value = false
}

const props = withDefaults(
  defineProps<{
    study: ReviewItem
    studyDesignStage?: boolean
    screeningStage?: boolean
    synthesisStage?: boolean
    studyDesignDrawer?: boolean
    isMetadataExpanded?: boolean
  }>(),
  {
    synthesisStage: true,
    isMetadataExpanded: false,
  },
)

const loading = useLoading()
const isLoading = ref(false)
const snackbar = useSnackbar()

const expansionPanel = useExpansionPanel()

const shouldRender = ref(false)

function onIntersectionObserver([
  { isIntersecting },
]: IntersectionObserverEntry[]) {
  if (!isIntersecting) return
  shouldRender.value = true
}

const exclusionCriterion = computed(() => {
  if (
    props.study.titleAndAbstractScreening !== '' &&
    props.study.titleAndAbstractScreening !== 'Included'
  )
    return props.study.titleAndAbstractScreening
  if (
    props.study.fullTextScreening !== '' &&
    props.study.fullTextScreening !== 'Included'
  )
    return props.study.fullTextScreening
  return ''
})

async function markStudyAsDuplicate(parentStudyId: Id) {
  try {
    startLoading()
    await review.markStudyAsDuplicate(props.study.id, parentStudyId)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Study marked as duplicate successfully',
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    stopLoading()
  }

  stopLoading()
}

async function markStudyAsNotDuplicate() {
  try {
    startLoading()
    await review.markStudyAsNotDuplicate(props.study.id)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Study marked as not duplicate successfully',
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function setTitleAndAbstractScreening(key: string) {
  if (
    !props.study.pdfFile &&
    review.entity.value?.plan?.screeningPlan?.titleAndAbstractCriteria
      ?.length === 0 &&
    key === 'Included'
  ) {
    snackbar.show(
      SnackbarState.ERROR,
      'You must upload the study to continue. Please upload a clean document without highlights or comments',
    )
    return
  }
  try {
    startLoading()
    await review.setStudyTitleAndAbstractScreening(props.study.id, key)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Successfully updated title and abstract screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function clearTitleAndAbstractScreening() {
  try {
    startLoading()
    await review.clearStudyTitleAndAbstractScreening(props.study.id)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Successfully cleared title and abstract screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function setFullTextScreening(key: string) {
  try {
    startLoading()
    await review.setStudyFullTextScreening(props.study.id, key)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Successfully updated full text screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function clearFullTextScreening() {
  try {
    startLoading()
    await review.clearStudyFullTextScreening(props.study.id)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Successfully cleared full text screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

function startLoading() {
  loading.start()
  isLoading.value = true
}
function stopLoading() {
  loading.stop()
  isLoading.value = false
}

const duplicationParentStudy = computed(() => {
  if (props.study.state !== StudyState.DUPLICATE) return null
  return review.entity.value.studies.find(
    (a) => a.id === props.study.parentStudyId,
  )
})
</script>

<style>
.duplication-parent::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  bottom: -2px;
  border-left: 6px solid;
  @apply border-violet-600;
  z-index: 1;
}

.duplication-parent::after {
  content: '';
  position: absolute;
  left: -2px;
  top: calc(50% - 5px);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  @apply bg-violet-600;
}

.duplicate::before {
  content: '';
  position: absolute;
  left: 0;
  top: -2px;
  bottom: 0;
  border-left: 6px solid;
  @apply border-violet-600;
  z-index: 1;
}
.maybe-dupllicate::before {
  content: '';
  position: absolute;
  left: 0;
  top: -2px;
  bottom: 0;
  width: 5px;
  background-image: linear-gradient(
    45deg,
    #7c3aed 33.33%,
    #ffffff 33.33%,
    #ffffff 50%,
    #7c3aed 50%,
    #7c3aed 83.33%,
    #ffffff 83.33%,
    #ffffff 100%
  );
  background-size: 21.21px 21.21px;
  z-index: 1;
}

.quarantined {
  background-image: linear-gradient(
    135deg,
    #f2e42719 25%,
    #00000019 25%,
    #00000019 50%,
    #f2e42719 50%,
    #f2e42719 75%,
    #00000019 75%,
    #00000019 100%
  );

  background-size: 113.14px 113.14px;
}

.maybe-dupllicate {
  background-image: linear-gradient(
    135deg,
    #f2e42719 25%,
    #00000019 25%,
    #00000019 50%,
    #f2e42719 50%,
    #f2e42719 75%,
    #00000019 75%,
    #00000019 100%
  );

  background-size: 113.14px 113.14px;
}
</style>
