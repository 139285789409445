<template>
  <div class="h-full flex flex-col overflow-hidden">
    <div class="flex px-8 border-b flex-col gap-6 w-full sticky top-0 z-50">
      <div class="dark:bg-[#103047] bg-white z-50 space-y-6 py-4">
        <h2
          class="text-lg pb-4 font-bold text-primary dark:text-white flex items-center gap-2"
        >
          Assign members to project
          <FloatingMenu>
            <template #activator="{ show }">
              <button class="block" @click="show">
                <InformationCircleIcon class="w-5 h-5 text-black/80" />
              </button>
            </template>
            <template #content>
              <div class="w-64 p-2">
                <p>Assign users and external members to the project.</p>
                <div>
                  <a
                    class="text-primary underline"
                    target="_blank"
                    href="/docs/projects.html#assigning-members-to-a-project"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </template>
          </FloatingMenu>
        </h2>
        <div class="flex flex-col gap-4 bg-white dark:bg-[#103047]">
          <div class="flex items-center gap-4">
            <ProjectMembers :project="project" />

            <div class="w-full">
              <Scrollbar class="pb-3.5">
                <div class="w-full flex gap-4">
                  <template
                    v-for="user in project.users"
                    :key="'user-' + user.id"
                  >
                    <Draggable
                      :id="user?.id"
                      v-slot="{ isDragged }"
                      @update:is-dragged="
                        (isDragged: boolean) =>
                          updateDraggedItem(
                            user?.id,
                            DraggedItemType.member,
                            isDragged,
                          )
                      "
                    >
                      <div
                        class="rounded-full dark:text-white text-black text-center max-w-fit border-2 py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap hover:cursor-pointer"
                        :class="{
                          'border-white text-white': isDragged,
                          'border-transparent': !isDragged,
                        }"
                        :style="{
                          'background-color': `${
                            usersColors[user?.id ?? '']
                          }40`,
                        }"
                      >
                        <div
                          class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center"
                          :style="{
                            backgroundColor: usersColors[user?.id ?? ''],
                          }"
                        >
                          <p class="h-3 w-3 font-medium leading-3">
                            {{ buildUserFullname(user)[0].toUpperCase() }}
                          </p>
                        </div>
                        <div class="leading-none">
                          {{ buildUserFullname(user) }}
                        </div>
                        <div class="w-5 h-5 flex items-center justify-center">
                          <button
                            v-tooltip="
                              !canDeleteMember()
                                ? 'Cannot remove member. Assigned in some reviews.'
                                : ''
                            "
                            :class="{
                              'opacity-50 cursor-not-allowed':
                                !canDeleteMember(),
                            }"
                            class="hover:bg-transparent"
                            @click.stop="
                              removeProjectMember(project.id!, user?.id)
                            "
                          >
                            <XMarkIcon class="h-[16.5px]" />
                          </button>
                        </div>
                      </div>
                    </Draggable>
                  </template>
                </div>
              </Scrollbar>
            </div>
          </div>

          <div class="flex items-center gap-4">
            <ProjectExternalMembers :project-id="project.id!" />
            <div class="w-full">
              <Scrollbar class="pb-3.5">
                <div class="flex gap-4">
                  <template
                    v-for="externalMember in project.externalMembers"
                    :key="'external-member-' + externalMember.id"
                  >
                    <Draggable
                      :id="externalMember.id"
                      v-slot="{ isDragged }"
                      @update:is-dragged="
                        updateDraggedItem(
                          externalMember.id,
                          DraggedItemType.externalMember,
                          $event,
                        )
                      "
                    >
                      <div
                        class="bg-[#D8DCDE] rounded-full text-black text-center max-w-fit border-2 py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap hover:cursor-pointer"
                        :class="{
                          'border-white text-white': isDragged,
                          'border-transparent': !isDragged,
                        }"
                      >
                        <div
                          class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center bg-[#04517066]"
                        >
                          <p class="h-3 w-3 font-medium leading-3 text-white">
                            {{ externalMember.name[0].toUpperCase() }}
                          </p>
                        </div>

                        <div class="leading-none">
                          {{ externalMember.name }}
                        </div>
                        <div class="w-5 h-5 flex items-center justify-center">
                          <button
                            v-tooltip="
                              !canDeleteExternalMember()
                                ? 'Cannot remove external Member. This member is assigned as an external approver in some reviews.'
                                : ''
                            "
                            class="hover:bg-transparent"
                            :class="{
                              'opacity-50 cursor-not-allowed':
                                !canDeleteExternalMember(),
                            }"
                            @click.stop="
                              removeProjectExternalMember(
                                project.id!,
                                externalMember.id,
                              )
                            "
                          >
                            <XMarkIcon class="h-[16.5px]" />
                          </button>
                        </div>
                      </div>
                    </Draggable>
                  </template>
                </div>
              </Scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReviewMembersAssignment :dragged-item="draggedItem" :project="project" />
  </div>
</template>
<script setup lang="ts">
import { Project } from '@core/domain/models/project.model'
import { User } from '@core/domain/models/user.model'
import { computed, ref } from 'vue'
import Scrollbar from '@app/components/Global/Scrollbar.vue'
import XMarkIcon from '@app/components/Icons/XMarkIcon.vue'
import ProjectExternalMembers from './ProjectMembers/ProjectExternalMembers.vue'
import ProjectMembers from './ProjectMembers/ProjectMembers.vue'
import useProjects from '@app/views/Projects/use-projects'

import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import ReviewMembersAssignment from './ReviewMembersAssignment.vue'
import Draggable from '@app/components/Draggable.vue'

const props = defineProps<{
  project: Project
}>()

const { removeProjectMember, removeProjectExternalMember } = useProjects()

enum DraggedItemType {
  member,
  externalMember,
}
const draggedItem = ref<{ id: string; type: DraggedItemType | undefined }>({
  id: '',
  type: undefined,
})

function updateDraggedItem(
  itemId: string,
  type: DraggedItemType,
  state: boolean,
) {
  if (state) {
    draggedItem.value.id = itemId
    draggedItem.value.type = type
  } else {
    draggedItem.value.id = ''
    draggedItem.value.type = undefined
  }
}

const availableColors = [
  '#A1CAE9',
  '#AFB5F1',
  '#C8AFF1',
  '#F6BBFB',
  '#F8AFD2',
  '#F8AFAF',
  '#F8C9AF',
  '#F8DFAF',
  '#EBE28F',
  '#C2F8AF',
  '#AFF8C3',
  '#AFF8EB',
  '#83D6E9',
  '#C8BFFF',
  '#FFBFDA',
  '#FFB6BA',
  '#FFC19E',
  '#D5DF9A',
  '#B8DF9A',
  '#9ADFAD',
  '#B1F1FF',
  '#84B5FF',
  '#8491FF',
  '#AA8CFF',
  '#FFB8FC',
  '#FF9595',
]

const usersColors = computed<{ [userId: string]: string }>(
  () =>
    props.project.users?.reduce(
      (a, u, i) => ({ ...a, [u.id]: availableColors[i] }),
      {},
    ) ?? {},
)

const canDeleteMember = () => {
  return true
}

const canDeleteExternalMember = () => {
  return true
}

function buildUserFullname(user: User) {
  if (!user) return ''
  let fullName = ''
  if (user.firstName) {
    fullName += user.firstName
  }
  if (user.lastName) {
    fullName += ' ' + user.lastName
  }
  if (!fullName) {
    fullName = user.email
  }
  return fullName
}
</script>
