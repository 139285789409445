<template>
  <div
    v-tooltip="studyIssues"
    class="cursor-pointer sticky left-0 z-40 bg-white border-l-[16px] border-l-white"
    @click="expandCard"
    @mouseover="onMouseOver"
    @mouseout="onMouseOut"
  >
    <DropZoneFileUpload
      class="w-full h-full"
      :accepted-file-types="['application/pdf', 'pdf']"
      @file-dropped="uploadStudyPdfFile"
    >
      <template #default="{ hasFileDraggedOver }">
        <div
          ref="expandablePanelElement"
          class="absolute left-0"
          style="top: -74px"
        />
        <div
          class="relative border border-[#D9E4EA] h-full"
          :class="{
            'bg-azureish-white/25': isHoveredOver,
            '!border-green-600 ': hasFileDraggedOver,

            duplicate: article.parentStudyId,
            'maybe-dupllicate': article.maybeParentStudyId,
            'duplication-parent': isParentStudy,
            quarantined: article.state === StudyState.QUARANTINED,
          }"
        >
          <div class="py-3 dark:border-[#345C7C] px-4 flex flex-col shrink-0">
            <ExpansionPanel
              :model-value="expansionPanel.isShown.value"
              disable-close-animation
              disable-open-animation
            >
              <template #activator>
                <div class="space-y-2">
                  <div
                    class="inline-flex items-start justify-between w-full gap-2 min-w-0"
                  >
                    <div class="flex gap-2 items-start">
                      <div class="flex items-center gap-2">
                        <StudyFavoriteButton
                          :study="article"
                          :is-readonly="review.isReviewReadonly.value"
                          @toggle-favorite="toggleFavorite"
                        />
                      </div>
                      <div class="w-full min-w-0 space-y-2">
                        <div
                          class="flex justify-between gap-2 w-full sticky bottom-0 right-0 text-base"
                        >
                          <!-- eslint-disable vue/no-v-html -->
                          <p
                            v-if="article.metadata.title"
                            class="font-medium"
                            :class="{
                              'line-clamp-3': props.isMetadataExpanded,
                              'line-clamp-2': !props.isMetadataExpanded,
                            }"
                            v-html="formattedTitle"
                          />
                          <p v-else class="text-sm text-gray-400 w-full">
                            Title not available
                          </p>
                        </div>
                      </div>
                    </div>

                    <FloatingMenu v-if="!review.isReviewReadonly.value">
                      <template #activator="{ show }">
                        <button @click.stop="show">
                          <EllipsisVerticalIcon class="w-5 h-auto" />
                        </button>
                      </template>
                      <template #content>
                        <div>
                          <StudyEdit
                            :id="article.id"
                            :meta-data="{
                              abstract: article.metadata.abstract ?? '',
                              authors: [...article.metadata.authors],
                              doi: article.metadata.doi ?? '',
                              title: article.metadata.title ?? '',
                              issueNumber: article.metadata.issueNumber ?? '',
                              journal: article.metadata.journal ?? '',
                              pagesNumber: article.metadata.pagesNumber ?? '',
                              publishYear: article.metadata.publishYear ?? '',
                              volumeNumber: article.metadata.volumeNumber ?? '',
                            }"
                          />

                          <SubmitComplaint :study="article" />
                        </div>
                      </template>
                    </FloatingMenu>
                  </div>
                  <div class="flex justify-between w-full">
                    <div class="text-[#617177] text-sm inline-flex">
                      <div class="flex gap-2 flex-wrap">
                        <div
                          v-for="(author, index) in authorsToDisplay"
                          :key="author + index"
                          v-tooltip="
                            !expansionPanel.isShown.value ? author : ''
                          "
                          class="text-xs px-2 py-1 bg-[#E1E4E5] text-black rounded-md h-6"
                          :class="{
                            'max-w-[94px]': !expansionPanel.isShown.value,
                          }"
                        >
                          <p class="truncate">{{ author }}</p>
                        </div>
                        <button
                          v-if="
                            article.metadata.authors &&
                            article.metadata.authors.length >
                              authorsToDisplayCount &&
                            !expansionPanel.isShown.value
                          "
                          v-tooltip="'Click to list all authors'"
                          class="text-xs px-1 py-1 bg-[#E1E4E5] rounded-md text-black h-6"
                          @click.stop="displayAllAuthors = !displayAllAuthors"
                        >
                          ...
                        </button>
                      </div>
                    </div>
                    <div v-if="!expansionPanel.isShown.value">
                      <button
                        @click.stop="
                          !!article.pdfFile?.path
                            ? donwloadStudyPdfFile(article.id)
                            : ''
                        "
                      >
                        <PdfIcon
                          class="w-6 h-6 text-primary"
                          :class="{
                            'text-green-600': isMatchFoundInPdf,
                            '!text-black/30': !article.pdfFile?.path,
                            'animate-bounce !text-primary': isPdfFetching,
                          }"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </template>
              <template #default>
                <div class="w-full">
                  <div class="my-6 text-sm flex justify-between gap-12">
                    <div class="flex items-center gap-2">
                      <FloatingMenu
                        placement="top"
                        :offset="[0, 5]"
                        :arrow="true"
                      >
                        <template #activator="{ show, isShown }">
                          <div
                            v-if="isPdfFetching"
                            v-tooltip="'Fetching for PDF'"
                          >
                            <LoadingIcon class="w-5 h-5" />
                          </div>
                          <div v-else class="flex h-7">
                            <button
                              class=""
                              :class="[
                                !!article.pdfFile?.path
                                  ? 'rounded-l-md border border-gray-300'
                                  : 'text-gray-500 cursor-not-allowed',
                              ]"
                              @click.stop="
                                !!article.pdfFile?.path
                                  ? donwloadStudyPdfFile(article.id)
                                  : ''
                              "
                            >
                              <PdfIcon
                                class="w-6 h-6 text-primary"
                                :class="{
                                  'text-green-600': isMatchFoundInPdf,
                                  '!text-black/30': !article.pdfFile?.path,
                                }"
                              />
                            </button>

                            <button
                              v-if="!!article.pdfFile?.path"
                              class="rounded-r-md border border-gray-300 p-1 hover:bg-black/5"
                              @click.stop="show"
                            >
                              <ChevronDownIcon
                                class="w-4 h-4 stroke-[3] transition-all"
                                :class="{
                                  'rotate-180': !isShown,
                                }"
                              />
                            </button>
                          </div>
                        </template>

                        <template #content>
                          <div
                            class="dark:bg-[#103047] dark:text-white/70 dark:border-white/10"
                            :class="{
                              'bg-azureish-white/25': isHoveredOver,
                            }"
                          >
                            <button
                              v-tooltip="
                                !!article.pdfFile?.path
                                  ? 'Click to download PDF'
                                  : ''
                              "
                              class="flex w-full px-4 py-2 hover:bg-black/5 dark:hover:bg-[#344F62]"
                              :class="[
                                !!article.pdfFile?.path
                                  ? ''
                                  : 'text-gray-500 cursor-not-allowed',
                              ]"
                              @click.stop="
                                !!article.pdfFile?.path
                                  ? donwloadStudyPdfFile(article.id)
                                  : ''
                              "
                            >
                              Download
                            </button>
                            <FloatingMenu
                              v-if="
                                !!article.pdfFile?.path &&
                                !review.isReviewReadonly.value
                              "
                              placement="right"
                              :offset="[0, 0]"
                            >
                              <template #activator="{ show }">
                                <button
                                  v-tooltip="'Click to delete PDF'"
                                  class="flex w-full px-4 py-2 hover:bg-black/5 dark:hover:bg-[#344F62]"
                                  :class="[
                                    !!article.pdfFile?.path
                                      ? ''
                                      : 'text-gray-500 cursor-not-allowed',
                                  ]"
                                  @click.stop="show"
                                >
                                  Delete
                                </button>
                              </template>
                              <template #content="{ hide }">
                                <div class="w-96 p-4">
                                  <p class="mb-2">
                                    Are you sure you want to delete the pdf
                                    file?
                                  </p>
                                  <div>
                                    <button
                                      class="bg-neutral-500 hover:bg-neutral-600 text-white py-1 px-2 mr-1"
                                      @click.stop="hide"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      class="bg-red-500 hover:bg-red-600 text-white py-1 px-2"
                                      @click.stop="
                                        deletePdfFile(article?.id, hide)
                                      "
                                    >
                                      delete
                                    </button>
                                  </div>
                                </div>
                              </template>
                            </FloatingMenu>
                          </div>
                        </template>
                      </FloatingMenu>
                    </div>
                    <div class="flex items-center gap-2">
                      <a
                        class="underline flex items-center"
                        :href="article.metadata.url"
                        target="_blank"
                        @click.stop
                      >
                        <div
                          v-tooltip="
                            `${article.search?.source.name} (${searchIndex})`
                          "
                        >
                          <template v-if="sourceIconUrl">
                            <img
                              v-show="isSourceLogoValid"
                              :key="sourceIconUrl"
                              class="w-5 h-5"
                              :src="sourceIconUrl"
                              alt="source logo"
                              @load="
                                ({ target: imageElement }) =>
                                  checkImage(imageElement as HTMLImageElement)
                              "
                              @error="
                                ({ target: imageElement }) =>
                                  handleImageError(
                                    imageElement as HTMLImageElement,
                                  )
                              "
                            />
                            <div v-show="!isSourceLogoValid" class="flex">
                              <span
                                class="w-6 h-6 p-0.5 border-primary/50 border-2 text-primary/80 rounded-full"
                              >
                                <DatabaseIcon class="w-4 h-4" />
                              </span>
                            </div>
                          </template>
                          <div v-else class="flex">
                            <span
                              class="w-6 h-6 p-0.5 border-primary/50 border-2 text-primary/80 rounded-full"
                            >
                              <DatabaseIcon class="w-4 h-4" />
                            </span>
                          </div>
                        </div>
                      </a>

                      <div
                        v-if="!!article.metadata.doi"
                        class="flex items-center text-gray-400 hover:brightness-125"
                      >
                        <a
                          class="flex items-center underline"
                          :href="article.DoiLink"
                          target="_blank"
                          @click.stop
                        >
                          <ExternalLinkIcon class="w-5 h-5" /> DOI
                        </a>
                      </div>
                      <div class="text-[#53676E] flex items-center">
                        id:
                        <p v-html="formattedId"></p>
                      </div>
                    </div>
                  </div>

                  <div class="flex justify-between gap-2">
                    <div class="w-full sticky bottom-0 right-0">
                      <div
                        class="px-2 py-1 text-sm text-[#53676F] dark:bg-[#5C7282] dark:text-white block bg-[#E4F0F6] w-full min-w-0"
                      >
                        <p
                          v-if="article.metadata.abstract"
                          v-html="formattedAbstract"
                        ></p>
                        <p
                          v-else
                          class="text-gray-400 dark:text-gray-200 block w-full"
                        >
                          Abstract not available
                        </p>
                      </div>
                    </div>
                    <div
                      v-if="!article.metadata.abstract || !isAbstractValid"
                      class="flex justify-between"
                      @click.stop="
                        markStudyAbstractAsNotAvailable(
                          article.id,
                          'Abstract not available',
                        )
                      "
                    >
                      <FloatingMenu
                        trigger="hover"
                        :offset="[0, 10]"
                        placement="right"
                      >
                        <template #activator>
                          <div
                            class="flex w-full px-2 py-1 sticky bottom-0 right-0 hover:brightness-110"
                          >
                            <ExclamationIcon
                              class="fill-yellow-300 text-yellow-700 w-5 h-5"
                            />
                          </div>
                        </template>
                        <template #content>
                          <div class="px-2 py-1">
                            Click here to confirm that study abstract is not
                            available
                          </div>
                        </template>
                      </FloatingMenu>
                    </div>
                  </div>
                </div>
              </template>
            </ExpansionPanel>
          </div>
        </div>
      </template>
    </DropZoneFileUpload>
  </div>
</template>
<script setup lang="ts">
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import DropZoneFileUpload from '@app/components/Global/Inputs/DropZoneFileUpload.vue'
import ExclamationIcon from '@app/components/Icons/ExclamationIcon.vue'
import ExternalLinkIcon from '@app/components/Icons/ExternalLinkIcon.vue'
import PdfIcon from '@app/components/Icons/PdfIcon.vue'
import StudyEdit from '../StudyEdit.vue'
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import DatabaseIcon from '@app/components/Icons/DatabaseIcon.vue'
import ChevronDownIcon from '@app/components/Icons/ChevronDownIcon.vue'
import LoadingIcon from '@app/components/Icons/LoadingIcon.vue'
import { EllipsisVerticalIcon } from '@heroicons/vue/24/outline'
import SubmitComplaint from '../SubmitComplaint.vue'
import StudyFavoriteButton from '../StudyFavoriteButton.vue'
import { computed, nextTick, ref } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { isUrlValid } from '@app/utils/urlValidation'
import { SnackbarState, StudyState } from '@app/types'
import { Article } from '@core/domain/models/article.model'
import { applyHighlights } from '@app/utils/words-highlighter'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import useExpansionPanel from '@app/composables/use-expansion-panel'
import { hideAll } from 'tippy.js'
import { errorMessage } from '@app/utils/error-message'
import { HttpException } from '@core/exceptions/http.exception'
import { Id } from '@core/domain/types/id.type'

const props = defineProps<{
  article: Article
  isMetadataExpanded?: boolean
  expansionPanel: ReturnType<typeof useExpansionPanel>
}>()

const loading = useLoading()
const isLoading = ref(false)
const snackbar = useSnackbar()

const review = injectStrict(ReviewKey)
const displayAllAuthors = ref(false)
const isSourceLogoValid = ref(true)

const searchIndex = `Search ${
  review.entity.value.getSearchIndex(props.article) + 1
}`
const expandablePanelElement = ref<HTMLElement>()

const isPdfFetching = computed(() => {
  return review.runningStudiesPdfImportsMap.value.has(props.article.id)
})

const sourceIconUrl = ref(
  isUrlValid(props.article.search?.source.url ?? '')
    ? `https://www.google.com/s2/favicons?domain=${encodeURIComponent(
        props.article.search?.source.url ?? '',
      )}&sz=64`
    : '',
)

function checkImage(target: HTMLImageElement) {
  const img = target

  if (img.naturalWidth === 5 && img.naturalHeight === 5) {
    isSourceLogoValid.value = false
  } else {
    isSourceLogoValid.value = true
  }
}

function handleImageError(target: HTMLImageElement) {
  target.src = '/sources-icons/custom-source.png'
}
const authorsToDisplayCount = computed(() => (props.isMetadataExpanded ? 5 : 3))
const authorsToDisplay = computed(() => {
  if (
    formattedAuthors.value.length > authorsToDisplayCount.value &&
    !displayAllAuthors.value &&
    !props.expansionPanel.isShown.value
  ) {
    return formattedAuthors.value.slice(0, authorsToDisplayCount.value)
  } else {
    return formattedAuthors.value
  }
})

const isAbstractValid = computed(() => {
  return (
    (props.article.metadata.abstract?.[0] !== '.' ||
      props.article.metadata.abstract?.[1] !== '.' ||
      props.article.metadata.abstract?.[2] !== '.') &&
    (props.article.metadata.abstract?.[
      props.article.metadata.abstract.length - 1
    ] !== '.' ||
      props.article.metadata.abstract?.[
        props.article.metadata.abstract.length - 2
      ] !== '.' ||
      props.article.metadata.abstract?.[
        props.article.metadata.abstract.length - 3
      ] !== '.') &&
    props.article.metadata.abstract !== 'N/A'
  )
})

const formattedAbstract = computed(() => {
  let abstract = props.article.metadata.abstract
  if (!abstract) return ''

  if (review.filtering.terms.value.length > 0) {
    abstract = applyHighlights(abstract, review.filtering.terms.value)
  }
  const abstractTitlesRegex = /\b([A-Z][A-Z\s]*:)/gim
  abstract = abstract.replaceAll('\n', '<br>')
  abstract = abstract.replace(abstractTitlesRegex, '<strong>$1</strong> ')
  return abstract
})

const formattedTitle = computed(() => {
  let title = props.article.metadata.title
  if (!title) return ''

  if (review.filtering.terms.value.length > 0) {
    title = applyHighlights(title, review.filtering.terms.value)
  }
  return title
})

const formattedId = computed(() => {
  let id = props.article.id.toString()
  if (!id) return ''
  if (review.filtering.terms.value.length > 0) {
    id = applyHighlights(id, review.filtering.terms.value)
  }
  return id
})

const formattedAuthors = computed(() => {
  let authors = [...(props?.article?.metadata?.authors ?? [])]
  if (!authors) return []
  if (review.filtering.terms.value.length > 0) {
    authors = authors.map((author) => {
      return applyHighlights(author, review.filtering.terms.value)
    })
  }
  return authors
})

const isMatchFoundInPdf = computed(() => {
  let isMatchFound = false
  if (review.filtering.terms.value.length > 0) {
    const searchTagsRegexString =
      review.filtering.terms.value.map((t) => `(?=.*${t})`).join('') + '.*'
    const searchRegex = new RegExp(`${searchTagsRegexString}`, 'i')
    isMatchFound = searchRegex.test(props.article.pdfContent ?? '')
  }

  return isMatchFound
})

async function toggleFavorite() {
  try {
    startLoading()
    if (!props.article.isFavorite) {
      await review.favoriteStudy(props.article.id)
      snackbar.show(
        SnackbarState.SUCCESS,
        'Study marked as favorite successfully',
      )
    } else {
      await review.unfavoriteStudy(props.article.id)
      snackbar.show(
        SnackbarState.SUCCESS,
        'Study marked as unfavorite successfully',
      )
    }
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function uploadStudyPdfFile(file: File) {
  try {
    startLoading()
    await review.uploadStudyPdfFile(props.article.id, file)
    snackbar.show(SnackbarState.SUCCESS, 'PDF file uploaded successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function deletePdfFile(studyId: Id, cb: () => void = () => {}) {
  try {
    startLoading()
    await review.deleteStudyPdfFile(studyId)
    snackbar.show(SnackbarState.SUCCESS, 'PDF file deleted successfully')
    cb()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    stopLoading()
  }
}

function isTextSelected() {
  return !!window?.getSelection()?.toString()
}

async function markStudyAbstractAsNotAvailable(studyId: Id, abstract: string) {
  try {
    startLoading()
    await review.editStudyAbstract(studyId, abstract)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Study abstract marked as not available successfully ',
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    stopLoading()
  }
}

function expandCard() {
  if (!isTextSelected()) {
    props.expansionPanel.toggle()
    if (props.expansionPanel.isShown.value) {
      nextTick(() =>
        expandablePanelElement.value!.scrollIntoView({
          behavior: 'smooth',
        }),
      )
    }
  }
}

const studyIssues = computed(() => {
  let content = ''
  if (props.article.isDuplicate) return ''

  if (props.article.maybeDuplicate) {
    content += '\u{2022} Confirm/reject the duplication.<br>'
  }
  if (
    props.article.search?.source.id !== 'clinical-trials-gov' &&
    props.article.search?.source.id !== 'maude' &&
    props.article.search?.source.id !== 'ictrp' &&
    (!props.article.metadata.publishYear ||
      props.article.metadata?.authors?.length === 0)
  ) {
    content += '\u{2022} Author is missing and Publish year is missing.<br>'
  }
  if (!props.article.metadata.title) {
    content += '\u{2022} Title is missing.<br>'
  }
  if (!props.article.metadata.abstract || !isAbstractValid.value) {
    content +=
      '\u{2022} Abstact missing or incomplete. Confirm/modify abstract.<br>'
  }
  return content
})

const studyFileName: string = (() => {
  let fileName = ''
  if (
    props.article.metadata.authors?.[0]?.split(',')?.[0] &&
    props.article.metadata.publishYear
  ) {
    fileName =
      props.article.metadata.authors?.[0]?.split(',')?.[0] +
      '-' +
      props.article.metadata.publishYear
  } else {
    fileName = 'study-id-' + props.article.id.toString()
  }

  return fileName.replace(/[^a-z0-9\s]/gi, '-').toLowerCase()
})()

async function donwloadStudyPdfFile(studyId: Id) {
  try {
    loading.start()
    const studyPdf = await review.getStudyPdfFile(studyId)
    const downloadElement = document.createElement('a')

    const pdfFileName = studyFileName + '.pdf'
    downloadElement.style.display = 'none'
    downloadElement.href = window.URL.createObjectURL(studyPdf)
    downloadElement.download = pdfFileName
    document.body.appendChild(downloadElement)
    downloadElement.click()
    window.URL.revokeObjectURL(downloadElement.href)
    downloadElement.remove()

    snackbar.show(SnackbarState.SUCCESS, 'PDF file downloaded successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    stopLoading()
  }
}

const isParentStudy = computed(() => {
  return (
    review.parentStudyIds.value.has(props.article.id) ||
    review.maybeParentStudyIds.value.has(props.article.id)
  )
})

function startLoading() {
  loading.start()
  isLoading.value = true
}
function stopLoading() {
  loading.stop()
  isLoading.value = false
}

const isHoveredOver = ref(false)

function onMouseOver() {
  isHoveredOver.value = true
}
function onMouseOut() {
  isHoveredOver.value = false
}
</script>
