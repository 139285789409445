<template>
  <div class="space-y-6">
    <h3 class="text-2xl text-primary font-medium flex items-center gap-2">
      Exclusion criteria
      <InfoTooltip
        text="Exclusion criteria refine the pool of studies by detailing what should be avoided."
        url="/docs/reviews.html#exclusion-criteria"
      />
    </h3>
    <div class="space-y-4">
      <div class="space-y-2">
        <div
          v-if="
            !review.isPlanReadonly.value ||
            titleAndAbstractScreeingCriteria.length > 0
          "
          class="flex gap-2"
        >
          <h3 class="text-base font-bold">Screening on title and abstract</h3>
          <Switch
            v-if="!review.isPlanReadonly.value"
            :model-value="titleAndAbstractScreeingCriteria.length > 0"
            @update:model-value="toggleTitleandAbstracto"
          />
        </div>
        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in titleAndAbstractScreeingCriteria"
            :key="'title-and-abstract-criterion-' + criterion"
            class="flex items-center gap-2 px-3 py-2 min-h-10 text-sm rounded-full text-red-800 bg-red-200 leading-none"
          >
            {{ criterion }}
            <button
              v-if="!review.isPlanReadonly.value"
              @click="deleteTitleAndAbstractCriterion(criterion)"
            >
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput
            v-if="
              !review.isPlanReadonly.value &&
              titleAndAbstractScreeingCriteria.length > 0
            "
            @save="addTitleAndAbstractCriterion($event)"
          >
            <template #activator="{ show }">
              <button
                class="text-sm border-2 p-2 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>

      <div class="space-y-2">
        <h3 class="text-base font-bold">Screening on full-text</h3>

        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in fullTextScreeingCriteria"
            :key="'full-text-criterion-' + criterion"
            class="flex items-center gap-2 px-3 py-2 min-h-10 text-sm rounded-full text-red-800 bg-red-200 leading-none"
          >
            {{ criterion }}
            <button
              v-if="!review.isPlanReadonly.value"
              @click="deleteFullTextCriterion(criterion)"
            >
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput
            v-if="!review.isPlanReadonly.value"
            @save="addFullTextCriterion($event)"
          >
            <template #activator="{ show }">
              <button
                key=""
                class="text-sm border-2 p-2 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import InfoTooltip from '@app/components/Global/InfoTooltip.vue'
import FloatingTextInput from '@app/components/Global/Inputs/FloatingTextInput.vue'
import Switch from '@app/components/Global/Switch.vue'
import XCircleIcon from '@app/components/Icons/XCircleIcon.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { HttpException } from '@core/exceptions/http.exception'
import { ref, watchEffect } from 'vue'

const loading = useLoading()
const snackbar = useSnackbar()

const review = injectStrict(ReviewKey)

const fullTextScreeingCriteria = ref<string[]>([])
const titleAndAbstractScreeingCriteria = ref<string[]>([])

watchEffect(() => {
  titleAndAbstractScreeingCriteria.value =
    review.entity.value?.plan?.screeningPlan?.titleAndAbstractCriteria ?? []

  fullTextScreeingCriteria.value =
    review.entity.value?.plan?.screeningPlan?.fullTextCriteria ?? []
})

async function toggleTitleandAbstracto() {
  loading.start()
  try {
    if (titleAndAbstractScreeingCriteria.value.length > 0) {
      await review.disableTitleAndAbstractScreening()
      snackbar.show(
        SnackbarState.SUCCESS,
        'Title and abstract screening disabled successfully',
      )
    } else {
      await review.enableTitleAndAbstractScreening()
      snackbar.show(
        SnackbarState.SUCCESS,
        'Title and abstract screening enabled successfully',
      )
    }
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while enabling/disabling title and abstract screening, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function addFullTextCriterion(criterion: string) {
  loading.start()
  try {
    await review.addFullTextCriterion(criterion)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Full text criterion added successfully',
    )
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while adding full text criterion, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function addTitleAndAbstractCriterion(criterion: string) {
  loading.start()
  try {
    await review.addTitleAndAbstractCriterion(criterion)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Title and abstract criterion added successfully',
    )
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while adding title and abstract  criterion, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function deleteFullTextCriterion(criterion: string) {
  loading.start()
  try {
    await review.deletFullTextCriterion(criterion)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Full text criterion deleted successfully',
    )
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while deleting full text criterion, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function deleteTitleAndAbstractCriterion(criterion: string) {
  loading.start()
  try {
    await review.deleteTitleAndAbstractCriterion(criterion)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Title and abstract criterion deleted successfully',
    )
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while deleting title and abstract criterion, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}
</script>
