<template>
  <div>
    <slot name="activator" :is-shown="isShown" :toggle="togglePanel" />
    <div
      class="scale-wrapper m-0"
      :style="{ maxHeight: maxHeight }"
      :class="[overflow]"
    >
      <div ref="content" class="scale-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
const { disableOpenAnimation, disableCloseAnimation } = defineProps<{
  disableOpenAnimation?: boolean
  disableCloseAnimation?: boolean
}>()

const isShown = defineModel<boolean | undefined>({ default: true })
const overflow = ref<'overflow-visible' | 'overflow-hidden'>(
  isShown.value ? 'overflow-visible' : 'overflow-hidden',
)

watch(
  () => isShown.value,
  () => {
    if (isShown.value) {
      expand()
    } else {
      collapse()
    }
  },
)
const content = ref<HTMLDivElement>()
const maxHeight = ref(isShown.value ? 'none' : '0')

function expand() {
  const height = content.value ? content.value.offsetHeight : 0

  if (!disableOpenAnimation) {
    maxHeight.value = `${height}px`
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        maxHeight.value = `${height}px`
      })
    })
  } else {
    maxHeight.value = 'none'
  }
  setTimeout(() => {
    maxHeight.value = 'none'
    overflow.value = 'overflow-visible'
  }, 200)
}

function collapse() {
  const height = content.value ? content.value.offsetHeight : 0

  if (!disableCloseAnimation) {
    maxHeight.value = `${height}px`
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        maxHeight.value = '0'
        overflow.value = 'overflow-hidden'
      })
    })
  } else {
    maxHeight.value = '0'
    overflow.value = 'overflow-hidden'
  }
}

const togglePanel = () => {
  isShown.value = !isShown.value
}
</script>

<style scoped>
.scale-wrapper {
  transition: max-height 0.2s ease-in-out;
}
</style>
