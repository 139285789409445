<template>
  <Scrollbar :id="id" class="h-full">
    <div class="flex">
      <div ref="contentArea" class="flex-1">
        <slot :reload-navigation="reloadNavigation" />
      </div>
      <div
        class="sticky top-0 right-0 h-full p-10 flex flex-col items-end gap-7"
        :style="{
          width: sidebarWidth,
        }"
      >
        <slot name="sidebar-top" />
        <button
          v-for="(part, index) in parts"
          :key="part.label + index"
          class="flex items-center gap-2 border-0 py-1.5 text-base font-medium"
          :class="{
            ' px-5 scale-110 bg-black/10 rounded-md text-[#4657EB]':
              part.active,
          }"
          :style="{
            marginRight: `${part.level * 20}px`,
          }"
          @click="scrollIntoView(part.element)"
        >
          {{ part.label }}

          <hr
            v-if="part.level === 0"
            class="w-[32px] h-1"
            :class="{
              'w-3 h-3 rounded-full': part.level > 0,
              'bg-[#4657EB]': part.active,
              'bg-black/30': !part.active,
            }"
          />

          <div
            v-else
            class="w-2 h-2 rounded-full"
            :class="[part.active ? 'bg-[#4657EB]' : 'bg-black/30']"
          />
        </button>
        <slot name="sidebar-bottom" />
      </div>
    </div>
  </Scrollbar>
</template>

<script setup lang="ts">
import { onMounted, ref, onBeforeUnmount } from 'vue'
import Scrollbar from './Scrollbar.vue'
import { v4 as uuid } from 'uuid'

withDefaults(
  defineProps<{
    sidebarWidth: string
  }>(),
  {
    sidebarWidth: 'auto',
  },
)

type Part = {
  label: string
  element: HTMLElement
  level: number
  active: boolean
}

const id = 'nav-' + uuid()
const contentArea = ref<HTMLDivElement | null>(null)
const parts = ref<Part[]>([])
const scrollingContainer = ref<HTMLElement | null>(null)

let animationFrameId: number | null = null

const updateActivePart = () => {
  const containerTop =
    scrollingContainer.value?.getBoundingClientRect().top ?? 0
  let highlightedElement: Part | null = null
  let lastInViewElement = null

  for (let i = parts.value.length - 1; i >= 0; i--) {
    const part = parts.value[i]
    const rect = part.element.getBoundingClientRect()

    if (rect.bottom < containerTop) {
      lastInViewElement = part
    }

    if (rect.top <= containerTop + 1 && rect.bottom >= containerTop - 1) {
      highlightedElement = part
      break
    }
  }

  if (!highlightedElement && lastInViewElement) {
    const nextIndex = parts.value.indexOf(lastInViewElement) + 1
    if (nextIndex < parts.value.length) {
      const nextElement = parts.value[nextIndex]
      if (nextElement.level >= lastInViewElement.level) {
        highlightedElement = nextElement
      }
    }
  }

  parts.value.forEach((p) => {
    p.active = p === highlightedElement
  })
}

const handleScroll = () => {
  if (animationFrameId !== null) {
    cancelAnimationFrame(animationFrameId)
  }
  animationFrameId = requestAnimationFrame(updateActivePart)
}

onMounted(() => {
  reloadNavigation()
  scrollingContainer.value = document.querySelector(
    `#${id} .simplebar-content-wrapper`,
  )
  scrollingContainer.value?.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  scrollingContainer.value?.removeEventListener('scroll', handleScroll)
})

function traverseNodes(node: Element, level = 0) {
  Array.from(node.children).forEach((child) => {
    let nextLevel = level
    if (child instanceof HTMLElement && child.hasAttribute('data-navigation')) {
      parts.value.push({
        label: child.dataset.navigation!,
        element: child,
        level,
        active: false,
      })
      nextLevel = level + 1
    }
    traverseNodes(child, nextLevel)
  })
}
function reloadNavigation() {
  parts.value.splice(0, parts.value.length)
  traverseNodes(contentArea.value!)
}

function scrollIntoView(element: HTMLElement) {
  element.scrollIntoView({
    behavior: 'smooth',
  })
}

defineExpose({
  reloadNavigation,
})
</script>
