<template>
  <div ref="wrapperElement" @click="!readonly ? open() : ''">
    <slot :id="id" name="label">
      <label class="text-sm text-primary font-medium" :for="id">
        {{ label }}
      </label>
    </slot>
    <div
      class="flex items-center justify-between px-2 py-1 rounded-md bg-white"
      :class="{
        shadow,
      }"
    >
      <input
        ref="textInput"
        :disabled="readonly"
        type="text"
        name=""
        :value="searchQuery"
        class="w-full focus:outline-none dark:text-white disabled:bg-transparent font-normal pr-6 truncate"
        :placeholder="placeholder"
        @input="onSearchQueryChange(($event.target as HTMLInputElement).value)"
        @keydown.down.prevent="highlightNextItem"
        @keydown.up.prevent="highlightPreviousItem"
        @keydown.enter.prevent="handleEnter"
      />

      <ChevronUpDownIcon class="w-5 h-5 text-black stroke-2 dark:text-white" />
    </div>

    <div ref="content">
      <div
        ref="scrollAreaElement"
        class="max-h-[200px] w-full overflow-auto font-normal z-50 rounded-b-md text-base rounded-md shadow"
      >
        <div :style="{ height: filteredItems.length * 39.98 + 'px' }">
          <template
            v-for="(group, groupName) in groupedItems"
            :key="'group-' + groupName"
          >
            <div
              v-if="groupName && groupName !== 'NONE'"
              class="px-2 py-1 bg-black/10"
            >
              {{ groupName }}
            </div>
            <template v-for="(item, localIndex) in group">
              <button
                v-if="isItemVisible(groupName, localIndex)"
                :key="item[itemOptions.valueProperty] as unknown as string"
                :ref="(el) => setItemRef(el as HTMLButtonElement)"
                class="px-2 py-1 truncate h-[40px] hover:bg-primary hover:text-white focus:bg-gray-200 focus:outline-none dark:hover:bg-[#36576D] w-full text-left font-normal last:rounded-b-lg"
                :class="{
                  'bg-primary text-white':
                    selectedItemIndex === getGlobalIndex(groupName, localIndex),
                }"
                @click="selectItem(item)"
              >
                <slot name="item" v-bind="item">
                  {{ item[itemOptions.displayProperty] }}
                </slot>
              </button>
            </template>
          </template>
        </div>
        <div
          v-if="filteredItems.length === 0 && !canAddItems"
          class="px-4 py-2 text-gray-500 font-normal"
        >
          no items found.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup generic="T">
import tippy, { Instance, Props } from 'tippy.js'
import { ref, computed, nextTick, onMounted, watchEffect, watch } from 'vue'
import { createDebounce } from '@app/utils/debounce'
import { ItemOptions } from './types'
import { onClickOutside, useScroll } from '@vueuse/core'
import ChevronUpDownIcon from '@app/components/Icons/ChevronUpDownIcon.vue'
import { v4 as uuid } from 'uuid'

const id = uuid()
const props = withDefaults(
  defineProps<{
    items: T[]
    itemOptions: ItemOptions<T>
    placeholder?: string
    debounceMs?: number
    label?: string
    canAddItems?: boolean
    readonly?: boolean
    shadow?: boolean
  }>(),
  {
    debounceMs: 0,
    canAddItems: false,
    readonly: false,
    shadow: true,
    label: '',
    placeholder: '',
  },
)

const shownItemsCount = ref(10)
const selectedItemIndex = ref(-1)

function highlightNextItem() {
  if (selectedItemIndex.value < filteredItems.value.length - 1) {
    selectedItemIndex.value += 1
    scrollIntoViewIfNeeded(selectedItemIndex.value)
  }
}

function highlightPreviousItem() {
  if (selectedItemIndex.value > 0) {
    selectedItemIndex.value -= 1
    scrollIntoViewIfNeeded(selectedItemIndex.value)
  }
}

const isOpen = ref(false)
const wrapperElement = ref()
onClickOutside(wrapperElement, () => reset())
const value = defineModel<string | number | object | Array<string | number>>()
const textInput = ref<HTMLInputElement>()
const content = ref<Element>()
const searchQuery = ref('')

watchEffect(() => {
  if (value.value !== null) {
    const selectedItem = props.items.find(
      (item) =>
        (item[props.itemOptions.valueProperty] as unknown as
          | string
          | number) === value.value,
    )
    if (selectedItem) {
      searchQuery.value = String(
        selectedItem[props.itemOptions.displayProperty],
      )
    } else {
      searchQuery.value = ''
    }
  }
})

const debounce = createDebounce()

let tippyInstance: Instance<Props> | null = null

onMounted(() => {
  tippyInstance = tippy(wrapperElement.value as Element, {
    content: content.value,
    hideOnClick: false,
    duration: [300, 100],
    allowHTML: true,
    offset: [0, 0],
    trigger: 'manual',
    placement: 'bottom-start',
    interactive: true,
    animation: 'shift-away',
    arrow: false,
    theme: 'input',
    maxWidth: 'none',
    onShow(instance) {
      const inputWidth = wrapperElement.value.getBoundingClientRect().width
      instance.popperInstance?.setOptions({
        modifiers: [
          {
            name: 'computeStyles',
            options: {
              adaptive: false,
              gpuAcceleration: false,
            },
          },
        ],
      })
      instance.popper.style.width = `${inputWidth}px`
    },
    onHide() {
      isOpen.value = false
    },
  })
})

function handleEnter() {
  if (
    selectedItemIndex.value >= 0 &&
    selectedItemIndex.value < filteredItems.value.length
  ) {
    selectItem(filteredItems.value[selectedItemIndex.value])
  } else if (props.canAddItems && searchQuery.value) {
    addNewItem(searchQuery.value)
  }
  searchQuery.value = ''
}

function addNewItem(newItemValue: string) {
  value.value = newItemValue
  nextTick(() => tippyInstance?.hide())
}

function onSearchQueryChange(v: string) {
  debounce(() => {
    searchQuery.value = v
    selectedItemIndex.value = -1
  }, props.debounceMs)
}

const filteredItems = computed(() => {
  return Object.keys(groupedItems.value)
    .map((key) => groupedItems.value[key])
    .flat()
})

const groupedItems = computed(() => {
  const query = searchQuery.value.toLowerCase()
  const { filterProperties, groupProperty } = props.itemOptions

  const filtered = props.items.filter((item) =>
    filterProperties.some((prop) =>
      String(item[prop]).toLowerCase().includes(query),
    ),
  )

  if (groupProperty) {
    return filtered.reduce(
      (acc, item) => {
        const groupKey = (item[groupProperty] as unknown as string) ?? 'NONE'
        if (!acc[groupKey]) {
          acc[groupKey] = []
        }
        acc[groupKey].push(item)
        return acc
      },
      {} as Record<string, T[]>,
    )
  } else {
    return filtered.reduce(
      (acc, item) => {
        if (!acc['NONE']) {
          acc['NONE'] = []
        }
        acc['NONE'].push(item)
        return acc
      },
      {} as Record<string, T[]>,
    )
  }
})

function selectItem(item: T) {
  value.value = item[props.itemOptions.valueProperty] as unknown as
    | string
    | number

  nextTick(() => {
    tippyInstance?.hide()
    textInput.value?.blur()
    selectedItemIndex.value = -1
  })
}

function open() {
  if (window.getSelection()?.toString()) return

  isOpen.value = true
  tippyInstance?.show()
  textInput.value?.select()
  searchQuery.value = ''
}

function reset() {
  const resetValue = props.items.find(
    (item) =>
      (item[props.itemOptions.valueProperty] as unknown as string | number) ===
      value.value,
  )
  searchQuery.value = resetValue
    ? String(resetValue[props.itemOptions.displayProperty])
    : ''
  nextTick(() => tippyInstance?.hide())
}

const scrollAreaElement = ref<HTMLElement | null>(null)
const { y } = useScroll(scrollAreaElement)
watch(y, () => {
  if (y.value >= 40 * shownItemsCount.value - 200)
    shownItemsCount.value = y.value / 40 + 10
})

const itemRefs = ref<HTMLButtonElement[]>([])

const setItemRef = (el: HTMLButtonElement) => {
  if (el) {
    itemRefs.value.push(el)
  }
}

watch(
  [isOpen, filteredItems],
  () => {
    itemRefs.value = []
  },
  { immediate: true },
)

function scrollIntoViewIfNeeded(index: number) {
  nextTick(() => {
    const itemElement = itemRefs.value[index]
    if (itemElement) {
      itemElement.scrollIntoView({ block: 'nearest' })
    }
  })
}

function getGlobalIndex(groupName: string, localIndex: number) {
  let globalIndex = 0
  for (const [group, items] of Object.entries(groupedItems.value)) {
    if (group === groupName) {
      return globalIndex + localIndex
    }
    globalIndex += items.length
  }
  return -1
}

function isItemVisible(groupName: string, localIndex: number) {
  const globalIndex = getGlobalIndex(groupName, localIndex)
  return globalIndex < shownItemsCount.value
}
</script>
<style>
.tippy-box[data-theme~='input'] .tippy-content {
  padding: 0;
  box-shadow: none;
  border: none;
  box-sizing: border-box;
}

.tippy-box[data-theme~='input'] > .tippy-backdrop {
  background-color: #fff;
}

.tippy-box[data-theme~='input'] {
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-top: none;
  color: #333;
  box-shadow: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
</style>
