<template>
  <div class="flex flex-col overflow-auto p-4 space-y-3">
    <h3 class="text-2xl text-primary font-medium flex items-center gap-2">
      Scope
    </h3>
    <div v-if="(synthesisPlan?.attributesStructure.length ?? 0) > 0">
      <TextInput
        ref="scopeSummary"
        v-model="scopesSummary"
        label="Scope summary"
        :placeholder="`Summarize the scope`"
        type="text"
        :multiline="true"
      />

      <AttributesStructureBuilder v-model="synthesisPlan" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'

import AttributesStructureBuilder from '../ReviewPreset/AttributeStructureBuilder/AttributesStructureBuilder.vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'

const review = injectStrict(ReviewKey)
const synthesisPlan = review.synthesisPlan

const scopesSummary = ref('')
</script>
